import { FC, useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import moment from "moment";

import { FormGroup, InfoTip, Input, InputSize } from "../../components";
import { FormContainer, SecondaryContainer } from "../../containers";
import { useFetch, useToggle } from "../../hooks";
import {
  MessageAlert,
  SectionTitle,
  StatusMessage,
  OtpEmailObject,
  EmailAuthModal,
} from "../../components/security";
import { validateEmail } from "../../utils/validate-email";
import { useTfaContext } from "../../context/TfaContext";
import { addPageViewEvent, inputClasses, TFA_PATH } from "../../utils";
import { IOtpEmail } from "../../interfaces";
import { eventDisableTfa, TfaTypes } from "../../utils/analytics";

export const EmailAuthenticator: FC = () => {
  const { t } = useTranslation();
  const { tfa, setTfaData } = useTfaContext();
  const { visible, toggle } = useToggle();
  const [otpEmailObj, setOtpEmailObj] = useState<OtpEmailObject>();
  const { emailOtpEnabled, emailUsed, emailOtpEnabledDate } = tfa;

  const { apiCall: sendOtpEmail, loading } = useFetch("post");
  const { apiCall: resetOtpEmail, loading: resetLoading } = useFetch("post");

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const sendOtpViaEmail: SubmitHandler<any> = (data: IOtpEmail) => {
    const otpEmailObject = {
      ...data,
      otpCode: "string",
      otpToken: "string",
    };

    sendOtpEmail("ManageApi/SendOtpViaEmail", otpEmailObject, (response) => {
      toast.success(t<string>("2fa/emailAuthenticator.sixDigitCodeSent"));
      setOtpEmailObj(response);
      toggle();
    });
  };

  const resetEmailOtp = () => {
    resetOtpEmail("ManageApi/ResetEmailOtp", {}, () => {
      toast.success(
        t<string>("2fa/emailAuthenticator.emailAuthenticatorReset")
      );
      setTfaData({
        ...tfa,
        emailOtpEnabled: false,
      });
      eventDisableTfa(TfaTypes.email);
      reset();
    });
  };

  const censorWord = (str: string) => {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  };

  const censorEmail = (email?: string) => {
    if (email) {
      var arr = email.split("@");
      return censorWord(arr[0]) + "@" + censorWord(arr[1]);
    }
  };

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t<string>("2fa/emailAuthenticator.2faEmailAuthenticatorTitle")}
        </title>
      </Helmet>

      <SecondaryContainer
        title={t("2fa/emailAuthenticator.2faEmailAuthenticatorTitle")}
        noDescription={emailOtpEnabled}
        description={t("2fa/emailAuthenticator.emailVerificationInfo")}
        to={TFA_PATH}
        headlineContent={
          emailOtpEnabled && (
            <MessageAlert
              message={`${t("2fa/emailAuthenticator.twoFaIsOnSince")}  ${
                !!emailOtpEnabledDate
                  ? moment(emailOtpEnabledDate).format("DD/MM/YYYY")
                  : ""
              } `}
              onClick={resetEmailOtp}
              disabled={resetLoading}
            />
          )
        }
      >
        {emailOtpEnabled && (
          <StatusMessage
            icon="Message"
            title={t("2fa/emailAuthenticator.emailAddress")}
            subTitle={censorEmail(emailUsed)}
            message={t("2fa/emailAuthenticator.verificationCodesSentByEmail")}
            hasVerify
            children={<></>}
          />
        )}

        {!emailOtpEnabled && (
          <FormContainer
            saveBtnLabel={t("2fa/emailAuthenticator.confirm")}
            onSubmit={handleSubmit(sendOtpViaEmail)}
            disabled={loading}
          >
            <SectionTitle
              title={t("2fa/emailAuthenticator.setupYourEmail")}
              message={t("2fa/emailAuthenticator.emailRequirement")}
            />

            <FormGroup
              label={t("2fa/emailAuthenticator.emailAddress")}
              lastChild
            >
              <div className="flex-1">
                <div className="md:w-1/2 md:flex-none">
                  <Input
                    {...register("email", {
                      required: `${t(
                        "2fa/emailAuthenticator.emailAddressRequired"
                      )}`,
                      validate: (value) =>
                        validateEmail(value) ||
                        `${t("2fa/emailAuthenticator.emailFieldNotValid")}`,
                    })}
                    error={!!errors.email && errors.email.message}
                    inputSize={InputSize.sm}
                    type="email"
                    className={inputClasses}
                    autoFocus
                    maxLength={320}
                  />
                </div>

                <InfoTip
                  className="mt-8"
                  title={`${t("2fa/emailAuthenticator.info")}:`}
                  description={t("2fa/emailAuthenticator.emailAddressUsage")}
                />
              </div>
            </FormGroup>
          </FormContainer>
        )}
      </SecondaryContainer>

      {visible && (
        <EmailAuthModal
          visible={visible}
          hide={toggle}
          otpEmailObj={otpEmailObj}
        />
      )}
    </>
  );
};
