import { FC } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useTfaContext } from "../../context/TfaContext";
import { useFetch } from "../../hooks";
import { IOtpEmail } from "../../interfaces";
import { inputClasses, TFA_RECOVERY_CODES_PATH } from "../../utils";
import { eventEnableTfa, TfaTypes } from "../../utils/analytics";
import { Input, InputSize } from "../input";
import { Modal, ModalSize } from "../modal";

export interface OtpEmailObject {
  email: string;
  otpCode: string;
  otpToken: string;
}

interface IEmailAuthModal {
  otpEmailObj?: OtpEmailObject;
  visible: boolean;
  hide: () => void;
}

export const EmailAuthModal: FC<IEmailAuthModal> = ({
  otpEmailObj,
  visible,
  hide,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTfaData, tfa } = useTfaContext();
  const { apiCall: enableEmailTfa, loading } = useFetch("post");

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enableEmail2fa = (data: IOtpEmail) => {
    const email2faObject = {
      ...data,
      email: otpEmailObj?.email || "",
      otpToken: otpEmailObj?.otpToken || "",
    };

    enableEmailTfa(
      `ManageApi/EnableEmail2fa`,
      email2faObject,
      (response) => {
        toast.success(
          `${t(
            "2fa/emailAuthenticator.emailAuthenticatorEnabledSuccessfully"
          )}!`
        );
        // try {
        //   (window as any).MeiroEvents.track("customEvent", {
        //     event_name: "2fa_email_cnccenter",
        //     email: otpEmailObj?.email || "",
        //     form_id: "2fa_email",
        //     page_url: document.location.origin + document.location.pathname
        //   })
        // } catch (error) {
        //   console.log('Meiro event 2fa error', error)
        // }

        const { userContact, date } = response;

        if (!!tfa?.recoveryCodesLeft && tfa?.recoveryCodesLeft > 0) {
          setTfaData({
            ...tfa,
            emailOtpEnabled: true,
            emailUsed: userContact,
            emailOtpEnabledDate: date,
            recoveryCodesLeft: 10,
          });
        } else {
          navigate(TFA_RECOVERY_CODES_PATH, {
            state: {
              codes: response,
            },
          });

          setTfaData({
            ...tfa,
            emailOtpEnabled: true,
            emailOtpEnabledDate: new Date(),
            emailUsed: otpEmailObj?.email,
            recoveryCodesLeft: 10,
          });
        }

        eventEnableTfa(TfaTypes.email);
        reset();
        hide();
      },
      (error) => {
        if (error.response?.status === 400) {
          // TODO: should get this text from response
          toast.error(t<string>("2fa/emailAuthenticator.codeNotValid"));
        }
      }
    );
  };

  return (
    <Modal
      visible={visible}
      hide={hide}
      modalSize={ModalSize.md}
      title={t("2fa/emailAuthenticator.2faEmailAuthenticatorTitle")}
      withFooter
      onFormSubmit={handleSubmit(enableEmail2fa as any)}
      confirmBtnText={t("2fa/authenticatorApp.verify")}
      disabled={loading}
      blockOutsideClick
      confirmBtnType="submit"
    >
      <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
        {t<string>("2fa/emailAuthenticator.emailWithConfirmationCodesSent")}{" "}
        <span className="font-bold">{otpEmailObj?.email}</span>.{" "}
        {t<string>("2fa/emailAuthenticator.provideConfirmatioCode")}
      </p>

      <div className="mb-8">
        <Input
          {...register("otpCode", {
            required: `${t("2fa.verificationCodeRequired")}`,
          })}
          labelProps={{
            children: t<string>("2fa.verificationCode"),
            className: "text-neutral block pb-0 mb-1.5",
          }}
          className={inputClasses}
          error={!!errors.otpCode && errors.otpCode.message}
          inputSize={InputSize.sm}
          maxLength={6}
          autoFocus
        />
      </div>

      <p className="text-sm text-gray-700">
        {t<string>("2fa/emailAuthenticator.checkSpamFolder")}
      </p>
    </Modal>
  );
};
