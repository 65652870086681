import { FC } from "react";
import ContentLoader from "react-content-loader";

interface IChangePasswordLoader {
  itemsCount: number;
}

export const ChangePasswordLoader: FC<IChangePasswordLoader> = ({
  itemsCount,
}) => {
  //   const size: Size = useWindowSize();
  //   const mobileScreen = !!size.width && size.width < 768;

  return (
    <>
      {[...Array(itemsCount)]?.map((item) => (
        <ContentLoader
          speed={2}
          width={600}
          key={item}
          height={800}
          className="sm:py-8 sm:px-6"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="23" rx="4" width="120" height="16" />

          <rect x="190" y="0" rx="4" width="150" height="16" />
          <rect x="190" y="23" rx="4" width={350} height="40" />

          <rect x="190" y="103" rx="4" width={150} height="16" />
          <rect x="190" y="127" rx="4" width={350} height="40" />

          <rect x="190" y="207" rx="4" width={150} height="16" />
          <rect x="190" y="231" rx="4" width={350} height="40" />

          <rect x="190" y="311" rx="4" width={150} height="24" />
          <rect x="190" y="343" rx="4" width={350} height="16" />
          <rect x="190" y="367" rx="4" width={100} height="16" />
        </ContentLoader>
      ))}
    </>
  );
};
