import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { cancelBtnClasses } from "../../utils";
import { BtnStyle, BtnType, Button } from "../button";
import { FormGroup } from "../form/FormGroup";
import { RoundedImg } from "../rounded-img";
import { Initials } from "../ui/Initials";

interface IChangePhotoProfile {
  picture: string;
  toggle: () => void;
  userName: string;
}

export const ChangePhotoProfile: FC<IChangePhotoProfile> = ({
  picture,
  toggle,
  userName,
}) => {
  const { t } = useTranslation();

  return (
    <FormGroup
      label={t("profile.profileTitle")}
      className="items-center"
      firstChild
    >
      {!!picture ? (
        <RoundedImg
          src={picture}
          alt="user"
          className="w-20 h-20 sm:w-36 sm:h-36"
        />
      ) : (
        <Initials text={userName} />
      )}

      <div className="relative sm:ml-10 flex-1 text-center sm:text-left">
        <Button
          type="button"
          btnStyle={BtnStyle.neutral}
          btnType={BtnType.secondary}
          className={cancelBtnClasses}
          onClick={toggle}
        >
          {`${t("profile.changePhotoProfile")}`}
        </Button>
      </div>
    </FormGroup>
  );
};
