import { forwardRef, InputHTMLAttributes, ReactNode, FC } from "react";

interface IRadioInput extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  inputName?: string;
  children?: ReactNode;
}

export const RadioInput: FC<IRadioInput> = forwardRef(
  ({ inputName, id, children, ...props }, ref: any) => {
    return (
      <div className="sm:mr-12 mr-2 flex items-center">
        <input type="radio" id={id} name={inputName} {...props} ref={ref} />

        <label
          htmlFor={id}
          className="ml-2 text-sm text-neutral cursor-pointer"
        >
          {children}
        </label>
      </div>
    );
  }
);
