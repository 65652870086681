import { FC } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";
import { cx } from "../../utils";

interface IHalfColumn {
  className?: string;
  isOn: boolean;
}

export const Indicator: FC<IHalfColumn> = ({ className, isOn }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx([
        "absolute sm:right-20 right-0 sm:top-1/2 sm:transform -translate-y-1/2 text-gray-700 flex-1 sm:flex-0",
        className,
      ])}
    >
      {isOn ? (
        <div className="text-primary flex items-center sm:w-12 mr-auto">
          <div>
            {" "}
            <Icon name="Checkmark" size={18} />
          </div>
          <span className="uppercase ml-1 text-sm">{t<string>("2fa.on")}</span>
        </div>
      ) : (
        <div className="flex items-center sm:w-12 ml-auto">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.4048 10C12.4048 10.2761 12.1809 10.5 11.9048 10.5H8.09526C7.81912 10.5 7.59526 10.2761 7.59526 10C7.59526 9.72386 7.81912 9.5 8.09526 9.5H11.9048C12.1809 9.5 12.4048 9.72386 12.4048 10Z"
              fill="#4B4B4B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.8335 9.99967C2.8335 6.04163 6.04212 2.83301 10.0002 2.83301C13.9582 2.83301 17.1668 6.04163 17.1668 9.99967C17.1668 13.9577 13.9582 17.1663 10.0002 17.1663C6.04212 17.1663 2.8335 13.9577 2.8335 9.99967ZM10.0002 3.83301C6.59441 3.83301 3.8335 6.59392 3.8335 9.99967C3.8335 13.4054 6.59441 16.1663 10.0002 16.1663C13.4059 16.1663 16.1668 13.4054 16.1668 9.99967C16.1668 6.59392 13.4059 3.83301 10.0002 3.83301Z"
              fill="#4B4B4B"
            />
          </svg>
          <span className="uppercase ml-1 text-sm">{t<string>("2fa.off")}</span>
        </div>
      )}
    </div>
  );
};
