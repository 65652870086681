import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Sidebar as SidebarLayout } from "../sidebar";
import {
  cx,
  personalizationDisabled,
  securityDisabled,
  myAccountDisabled,
  PAYMENTS_PATH,
  GIFTS_PATH,
  SUBSCRIPTIONS_PATH,
  featureEnabled,
  Permission,
} from "../../utils";
import { Calendar, Dashboard, Security, User, Voucher } from "../icons";
import { onlyForRoles } from "../../context";

export const Sidebar = () => {
  const { t } = useTranslation();
  const { betaTester } = Permission;

  return (
    <>
      <SidebarLayout
        wrapperClassName="sm:px-4 px-2 top-0"
        renderItem={({ to, title, icon }: any) => {
          return (
            <>
              <NavLink
                className={({ isActive }) =>
                  cx([
                    "nav-link flex items-center py-3 sm:px-2 mx-3 sm:mx-0 rounded-md text-sm hover:text-primary transition font-medium sm:focus:shadow-outlineGray focus:outline-none relative",
                    isActive
                      ? "text-primary sm:bg-gray-50 active"
                      : "text-primary-secText",
                  ])
                }
                to={to}
              >
                {icon ? (
                  <span className="w-5 h-5 mr-4 hidden sm:inline-block">
                    {icon}
                  </span>
                ) : null}
                <span className="whitespace-nowrap sm:whitespace-normal">
                  {title}
                </span>
              </NavLink>
            </>
          );
        }}
        list={[
          ...(!myAccountDisabled
            ? [
                {
                  title: t("sidebar.myAccount"),
                  to: "/",
                  icon: <User />,
                },
              ]
            : []),

          ...(!personalizationDisabled
            ? [
                {
                  title: t("sidebar.dataPersonalisation"),
                  to: "/personalization",
                  icon: <Dashboard />,
                },
              ]
            : []),
          ...(!securityDisabled
            ? [
                {
                  title: t("sidebar.security"),
                  to: "/security",
                  icon: <Security />,
                },
              ]
            : []),
          ...(featureEnabled["subscriptions"] === true &&
          onlyForRoles([betaTester])
            ? [
                {
                  title: t("sidebar.subscriptions"),
                  to: SUBSCRIPTIONS_PATH,
                  icon: <Calendar />,
                },
              ]
            : []),
          ...(featureEnabled["payments"] === true && onlyForRoles([betaTester])
            ? [
                {
                  title: t("sidebar.payments"),
                  to: PAYMENTS_PATH,
                  icon: <Icon name="Payment" size={20} />,
                },
              ]
            : []),

          ...(featureEnabled["gifts"] === true && onlyForRoles([betaTester])
            ? [
                {
                  title: "Gifts & Vouchers",
                  to: GIFTS_PATH,
                  icon: <Voucher />,
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
