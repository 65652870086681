import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { Router as MainRouter } from "./router/Router";
import {
  BtnSize,
  BtnStyle,
  Button,
  Header,
  Modal,
  ModalSize,
  Sidebar,
  ToastHolder,
} from "./components";
import { BiskoScript } from "./components/analytics/bisko/BiskoScript";
import {
  Compose,
  cx,
  getUserInfo,
  GIFTS_PATH,
  initializeMeiroEvents,
  PAYMENTS_PATH,
  primaryBtnClasses,
  RECOVERY_EMAIL_PATH,
  SUBSCRIPTIONS_PATH,
} from "./utils";
import {
  OrganizationSettingsProvider,
  TfaProvider,
  UserProfileProvider,
} from "./context";
import { useToggle } from "./hooks";

const App = () => {
  const { t } = useTranslation();
  const { visible, setVisible } = useToggle();
  const { access_token } = getUserInfo();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isPrimaryRoute = [
    "/",
    "/security",
    "/personalization",
    SUBSCRIPTIONS_PATH,
    PAYMENTS_PATH,
    GIFTS_PATH,
  ].includes(pathname);

  const redirectToScreen = (
    path: string,
    redirectPath: string,
    message: string,
    success: boolean
  ) => {
    if (pathname === path) {
      navigate(redirectPath);
      setTimeout(() => {
        if (success) {
          toast.success(`${t(message)}`);
        } else {
          toast.error(`${t(message)}`);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    redirectToScreen(
      "/LoginAlreadyAssociated",
      "/external-logins",
      "general.socialLoginExists",
      false
    );

    redirectToScreen(
      "/ExternalProviderLinkingError",
      "/external-logins",
      "general.socialLoginDidNotLinked",
      false
    );

    redirectToScreen(
      "/ExternalProviderLinkedSuccessfully",
      "/external-logins",
      "recoverEmail.recoveryEmailConnectedSuccessfully",
      true
    );

    redirectToScreen(
      "/RecoveryEmailRemovalFailed",
      RECOVERY_EMAIL_PATH,
      "recoverEmail.recoverEmailDidNotLinked",
      false
    );

    redirectToScreen(
      "/RecoveryEmailDisconnected",
      RECOVERY_EMAIL_PATH,
      "recoverEmail.recoveryEmailDissconnected",
      true
    );

    initializeMeiroEvents()

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !access_token && setVisible(true);
  }, [access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearAllCookies = () => {
    return document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  };

  // userManager.events.addSilentRenewError(() => {
  //   setVisible(true);
  // });



  return (
    <>
      <BiskoScript />

      <main className="relative min-h-screen sm:pb-14 pb-8">
        <>
          <Compose
            components={[
              UserProfileProvider,
              OrganizationSettingsProvider,
              TfaProvider,
            ]}
          >
            <Header />
            <div
              className={cx([
                isPrimaryRoute && "flex sm:flex-row flex-col main-container",
              ])}
            >
              {isPrimaryRoute && <Sidebar />}
              <Routes>
                <Route path="*" element={<MainRouter />} />
              </Routes>
            </div>
          </Compose>
        </>
        <ToastHolder />
      </main>

      {/* TODO: should be a component */}
      {visible && (
        <Modal
          visible={visible}
          title={t("sessionExpired.title")}
          infoModal
          modalSize={ModalSize.sm}
          warningModal
          blockOutsideClick
          noCloseButton
        >
          <p className="text-sm text-gray-700 mb-6 sm:mb-8">
            {t<string>("sessionExpired.title")}
          </p>

          <Button
            type="button"
            onClick={() => {
              window.location.reload();
              window.localStorage.clear();
              clearAllCookies();
            }}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.nomral}
            className={cx([
              "flex items-center mb-2 ml-auto",
              primaryBtnClasses,
            ])}
          >
            {t<string>("sessionExpired.description")}
          </Button>
        </Modal>
      )}
    </>
  );
};

export default App;
