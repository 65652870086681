import { FC } from "react";
import { cx } from "../../utils";

interface ICurrentDeviceLabel {
  text: string;
  className?: string;
}

export const CurrentDeviceLabel: FC<ICurrentDeviceLabel> = ({
  text,
  className,
}) => {
  return (
    <span
      className={cx([
        "text-xs bg-primary rounded-full py-0.5 px-2 text-white font-medium inline-block",
        className,
      ])}
    >
      {text}
    </span>
  );
};
