import { FC } from "react";

import { useTranslation } from "react-i18next";

import { cx, inputClasses, primaryBtnClasses } from "../../utils";
import { Button, ButtonProps, BtnSize, BtnStyle, InputSize, Input } from "../";

interface IAuthAppForm extends ButtonProps {
  className?: string;
  errors: any;
  register: any;
  onSubmit: () => void;
  disabled?: boolean;
}

export const AuthAppForm: FC<IAuthAppForm> = ({
  errors,
  register,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={onSubmit}>
      <div className="my-6 block sm:w-1/2">
        <Input
          labelProps={{
            children: t<string>("2fa/authenticatorApp.verificationCode"),
            className: "text-neutral block pb-0 mb-1.5",
          }}
          type="number"
          className={inputClasses}
          error={!!errors.code && errors.code.message}
          inputSize={InputSize.sm}
          {...register("code", {
            required: `${t("2fa.verificationCodeRequired")}`,
          })}
          autoFocus
        />
      </div>

      <Button
        type="submit"
        btnStyle={BtnStyle.primary}
        btnSize={BtnSize.nomral}
        className={cx([
          "flex items-center justify-center",
          "w-1/2 sm:w-1/4",
          primaryBtnClasses,
        ])}
        disabled={disabled}
      >
        <>
          {disabled && (
            <img
              src="/images/oval.svg"
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}
          {t("2fa/authenticatorApp.verify")}
        </>
      </Button>
    </form>
  );
};
