import { FC, useEffect } from "react";
import moment from "moment";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { SecondaryContainer } from "../../containers";
import {
  AuthAppForm,
  AuthAppItem,
  MessageAlert,
  StatusMessage,
} from "../../components/security";
import { useTfaContext } from "../../context/TfaContext";
import {
  addPageViewEvent,
  TFA_PATH,
  TFA_RECOVERY_CODES_PATH,
} from "../../utils";
import { IEnableAuthenticator } from "../../interfaces";
import { useFetch } from "../../hooks";
import {
  eventDisableTfa,
  eventEnableTfa,
  TfaTypes,
} from "../../utils/analytics";

export const AuthenticatorApp: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tfa, setTfaData } = useTfaContext();
  const { hasAuthenticator, authenticatorEnabledDate, recoveryCodesLeft } =
    !!tfa && tfa;
  const {
    apiCall: getAuthenticaor,
    response: qrCodeResponse,
    loading,
  } = useFetch("get");

  const { apiCall: saveAuthenticator, loading: saveAuthLoading } =
    useFetch("post");
  const { apiCall: resetAuthenticator, loading: resetAuthLoading } =
    useFetch("post");

  const AUTH_API = "ManageApi/EnableAuthenticator";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getAuthenticaor(AUTH_API);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAuthLoading]);

  const onSaveAuthenticator: SubmitHandler<any> = (
    data: IEnableAuthenticator
  ) => {
    saveAuthenticator(
      AUTH_API,
      data,
      (response) => {
        toast.success(
          `${t("2fa/authenticatorApp.authenticatorAppUpdatedSuccessfully")}!`
        );
        if (!!recoveryCodesLeft && recoveryCodesLeft > 0) {
          setTfaData({
            ...tfa,
            hasAuthenticator: true,
            authenticatorEnabledDate: response,
            recoveryCodesLeft: 10,
          });
        } else {
          setTfaData({
            ...tfa,
            hasAuthenticator: true,
            recoveryCodesLeft: 10,
            authenticatorEnabledDate: new Date(),
          });

          navigate(TFA_RECOVERY_CODES_PATH, {
            state: {
              codes: response,
            },
          });
        }

        reset();
        eventEnableTfa(TfaTypes.authApp);
      },
      (error) => {
        if (error?.response?.status === 400) {
          toast.error(t<string>("2fa/textMessage.codeNotValid"));
        }
      }
    );
  };

  const onResetAuthenticator = () => {
    resetAuthenticator(`ManageApi/resetAuthenticator`, {}, () => {
      toast.success(
        t<string>("2fa/authenticatorApp.authenticatorAppResetSuccessfully")
      );
      setTfaData({
        ...tfa,
        hasAuthenticator: false,
      });
      eventDisableTfa(TfaTypes.authApp);
    });
  };

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t<string>("2fa/authenticatorApp.2faAuthenticatorAppTitle")}
        </title>
      </Helmet>

      <SecondaryContainer
        title={t("2fa/authenticatorApp.2faAuthenticatorAppTitle")}
        to={TFA_PATH}
        noDescription={hasAuthenticator}
        description={t("2fa/authenticatorApp.authenticatorAppInfo")}
        headlineContent={
          <>
            {hasAuthenticator && (
              <MessageAlert
                message={`${t("2fa/authenticatorApp.authenticatorIsOnSince")} ${
                  !!authenticatorEnabledDate
                    ? moment(authenticatorEnabledDate).format("DD/MM/YYYY")
                    : t("2fa/authenticatorApp.unknown")
                }`}
                onClick={onResetAuthenticator}
                disabled={resetAuthLoading}
              />
            )}
          </>
        }
      >
        {hasAuthenticator && (
          <StatusMessage
            icon="CommentAlt"
            title={t<string>("2fa/authenticatorApp.2faAuthenticatorAppTitle")}
            subTitle={t<string>("2fa/authenticatorApp.authenticatorOnAndroid")}
            message={`${t("2fa/authenticatorApp.added")} ${
              !!authenticatorEnabledDate
                ? moment(authenticatorEnabledDate).format("DD/MM/YYYY")
                : t<string>("2fa/authenticatorApp.unknown")
            }`}
            hasVerify
            children={<></>}
          />
        )}

        {!hasAuthenticator && (
          <>
            <AuthAppItem
              qrObject={qrCodeResponse}
              loading={loading}
              children={<></>}
            />

            <AuthAppForm
              onSubmit={handleSubmit(onSaveAuthenticator)}
              register={register}
              errors={errors}
              disabled={saveAuthLoading}
              children={<></>}
            />
          </>
        )}
      </SecondaryContainer>
    </>
  );
};
