import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { inputClasses } from "../../utils";
import { Input, InputSize } from "../input";
import { HalfColumn } from "../layout/HalfColumn";

export const FullNameSection = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="relative flex sm:flex-row flex-col mt-8">
      <p className="text-black flex-shrink-0 sm:top-8 sm:mb-0 mb-6 w-48 relative">
        {`${t("profile.fullName")}`}
      </p>
      <div className="relative flex-1">
        <div className="w-full flex sm:flex-row flex-col">
          <HalfColumn left>
            <Input
              maxLength={40}
              {...register("firstName", {
                required: `${t("profile.firstNameRequired")}`,
              })}
              labelProps={{
                children: `${t("profile.firstName")}`,
                className: "text-neutral block pb-0 mb-1.5",
              }}
              className={inputClasses}
              error={!!errors.firstName && errors.firstName.message}
              inputSize={InputSize.sm}
            />
          </HalfColumn>

          <HalfColumn right>
            <Input
              maxLength={40}
              {...register("lastName", {
                required: `${t("profile.lastNameRequired")}`,
              })}
              labelProps={{
                children: `${t("profile.lastName")}`,
                className: "text-neutral block pb-0 mb-1.5",
              }}
              className={inputClasses}
              error={!!errors.lastName && errors.lastName.message}
              inputSize={InputSize.sm}
            />
          </HalfColumn>
        </div>
      </div>
    </div>
  );
};
