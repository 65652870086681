import { FC, ReactNode } from "react";
import { cx } from "../../utils";

interface IFormGroup {
  label: string;
  children: ReactNode;
  lastChild?: boolean;
  firstChild?: boolean;
  centeredLabel?: boolean;
  className?: string;
  py?: string;
  withLabel?: boolean;
}

export const FormGroup: FC<IFormGroup> = ({
  label,
  children,
  lastChild,
  firstChild,
  className,
  py,
  centeredLabel,
  withLabel,
}) => {
  return (
    <div
      className={cx([
        "flex flex-wrap items-start",
        centeredLabel && "items-center",
      ])}
    >
      <p
        className={cx([
          "w-full md:w-48 text-black mt-4 md:mt-0 mb-4 sm:mb-0",
          centeredLabel ? "pt-0" : "pt-0 md:pt-10",
          // withLabel && "relative top-7",
        ])}
      >
        {label}
      </p>

      <div
        className={cx([
          "flex flex-1 flex-wrap",
          !lastChild && "border-b border-gray-300",
          className,
          !py && !firstChild && "py-6 sm:py-8",
          firstChild && "pt-0 pb-6 sm:pb-8",
        ])}
      >
        {children}
      </div>
    </div>
  );
};
