import { FC } from "react";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Modal, ModalSize, Input, InputSize } from "..";
import { useTfaContext } from "../../context/TfaContext";
import { inputClasses } from "../../utils";
import { useFetch } from "../../hooks";
import { eventEnableTfa, TfaTypes } from "../../utils/analytics";

interface OptObject {
  phoneNumber: string;
  otpCode: string;
  otpToken: string;
}

interface ITextMessageModal {
  visible: boolean;
  hide: () => void;
  optObj?: OptObject;
}

export const TextMessageModal: FC<ITextMessageModal> = ({
  hide,
  optObj,
  visible,
}) => {
  const { t } = useTranslation();
  const { tfa, setTfaData } = useTfaContext();
  const { apiCall: enablePhoneNumber2fa, loading } = useFetch("post");
  const { apiCall: resendOtpViaSMS, loading: resendOtpViaSMSLoading } =
    useFetch("post");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onEnablePhoneNumber2fa = (data: any) => {
    const enablePhoneObject = {
      phoneNumber: optObj?.phoneNumber,
      ...data,
      otpToken: optObj?.otpToken,
    };

    enablePhoneNumber2fa(
      "ManageApi/EnablePhoneNumber2fa",
      JSON.stringify(enablePhoneObject),
      (response) => {
        toast.success(
          `${t("2fa/textMessage.phoneNumberAuthenticatorEnabledSuccessfully")}!`
        );
        setTfaData({
          ...tfa,
          smsOtpEnabled: true,
          phoneUsed: enablePhoneObject.phoneNumber,
          recoveryCodesLeft: 10,
        });
        hide();
        eventEnableTfa(TfaTypes.sms);
      },
      (error) => {
        if (error.response.status === 400) {
          // TODO: should get this text from response instead
          toast.error(t<string>("2fa/textMessage.codeNotValid"));
        }
      }
    );
  };

  const onResendOtpViaSMS = () => {
    const finalOb = {
      phoneNumber: optObj?.phoneNumber,
      otpCode: "string",
      otpToken: "string",
    };

    resendOtpViaSMS("ManageApi/SendOtpViaSMS", finalOb, () => {
      toast.success(t<string>("2fa/textMessage.sixDigitCodeSent"));
    });
  };

  return (
    <Modal
      visible={visible}
      hide={hide}
      modalSize={ModalSize.md}
      title={t("2fa/textMessage.confirmThatItWorks")}
      withFooter
      onConfirmClick={handleSubmit(onEnablePhoneNumber2fa)}
      confirmBtnText={t("2fa/authenticatorApp.verify")}
      disabled={loading}
      blockOutsideClick
    >
      <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
        {t<string>("2fa/textMessage.verificationCodeSent")}{" "}
        <span className="font-bold">{optObj?.phoneNumber}</span>.
      </p>

      <div className="mb-8">
        <Input
          {...register("otpCode", {
            required: `${t("2fa.verificationCodeRequired")}`,
          })}
          labelProps={{
            children: t<string>("2fa.verificationCode"),
            className: "text-neutral block pb-0 mb-1.5",
          }}
          error={!!errors.otpCode && errors.otpCode.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          maxLength={6}
          autoFocus
        />
      </div>

      <p className="text-sm text-gray-700">
        {t<string>("2fa/textMessage.didntGetIt")}{" "}
        <button
          className="ml-2 text-primary hover:underline focus:outline-none"
          onClick={onResendOtpViaSMS}
          type="button"
          disabled={resendOtpViaSMSLoading}
        >
          {t<string>("2fa/textMessage.resend")}
        </button>
      </p>
    </Modal>
  );
};
