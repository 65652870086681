import { FC, ReactNode, forwardRef, InputHTMLAttributes } from "react";

export interface IPhotoProfileLabel
  extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
}

export const PhotoProfileLabel: FC<IPhotoProfileLabel> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <label htmlFor="picture">
        <input
          type="file"
          {...props}
          name="picture"
          className="hidden"
          id="picture"
          accept={process.env.REACT_APP_PROFILE_IMAGE_FORMAT_ACCEPT || ""}
        />

        {children}
      </label>
    );
  }
);
