import { FC } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { PhotoProfileLabel, IPhotoProfileLabel } from "./PhotoProfileLabel";
import { useTranslation } from "react-i18next";
import { cx } from "../../utils";

interface IPhotoProfileEmpty extends IPhotoProfileLabel {}

export const PhotoProfileEmpty: FC<IPhotoProfileEmpty> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="mb-4 text-sm">{t<string>("profile.uploadPhotos")}</p>

      <PhotoProfileLabel {...props}>
        <div
          className={cx([
            "flex flex-col justify-center items-center",
            "w-52 h-52 mx-auto py-5",
            "border-dashed border border-gray-300 rounded-md",
            "text-sm text-gray-700 cursor-pointer",
            "hover:bg-gray-50 transition",
          ])}
        >
          <div className="text-black">
            <Icon name="Image" size={32} />
          </div>

          <span className="mt-4 mb-3">{t<string>("profile.profileImage")}</span>
          <span className="underline text-primary">
            {t<string>("profile.browse")}
          </span>
        </div>
      </PhotoProfileLabel>
    </>
  );
};
