import { FC, useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  Button,
  Modal,
  ModalSize,
  ShowPassButton,
  Input,
  InputSize,
} from "../../components";
import { TitleSection } from "../../components/personalization";
import { SecondaryContainer } from "../../containers";
import { useFetch, useHasPasswordApi, useToggle } from "../../hooks";
import { addPageViewEvent, inputClasses } from "../../utils";
import { IDeletePersonalData } from "../../interfaces";
import { eventDeletePersonalData } from "../../utils/analytics";
import { useAuth } from "oidc-react";

export const DeleteAccount: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [currentPass, setCurrentPass] = useState<boolean>(true);
  const { visible, toggle } = useToggle();

  const { apiCall: deleteAccount, loading } = useFetch("post");
  const { hasPassword } = useHasPasswordApi(true);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const hideModal = () => {
    toggle();
    reset();
  };

  useEffect(() => {
    addPageViewEvent();
  }, []);

  const onDeleteAccount: SubmitHandler<any> = (data: IDeletePersonalData) => {
    const deleteObj = {
      requirePassword: hasPassword || false,
      password: data?.password || "string",
    };
    deleteAccount(
      "ManageApi/DeletePersonalData",
      deleteObj,
      () => {
        eventDeletePersonalData();
        auth?.signOutRedirect();
      },
      (error) => {
        const err = error.response?.data;
        toast.error(t<string>(err));
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("deleteData.deleteDataTitle")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("deleteData.deleteDataTitle")}
        description={t("deleteData.deleteDataInfo")}
      >
        <TitleSection
          title={t("deleteData.allData")}
          message={t("deleteData.deleteDataAlert1")}
        />

        <div className="text-warning bg-warning bg-opacity-10 p-4 rounded-md mt-6 text-sm">
          {t<string>("deleteData.deleteDataAlert2")}
        </div>

        <Button className="mt-6" onClick={toggle}>
          {t<string>("deleteData.delete")}
        </Button>

        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title={t("deleteData.deleteDataTitle")}
          withFooter
          onFormSubmit={handleSubmit(onDeleteAccount)}
          confirmBtnText={t("deleteData.delete")}
          onCancel={hideModal}
          disabled={loading}
          blockOutsideClick
          confirmBtnType="submit"
          warningModal
        >
          <p className="text-sm text-primary-mainText mt-2">
            {t<string>("deleteData.deleteAccountModalInfo")}
          </p>

          {hasPassword && (
            <div className="relative">
              <Input
                {...register("password", {
                  required: `${t("deleteData.passwordIsRequired")}`,
                })}
                labelProps={{
                  children: t<string>("general.password"),
                  className: "text-neutral block pb-0 mb-1.5",
                }}
                error={!!errors.password && errors.password.message}
                inputSize={InputSize.sm}
                type={currentPass ? "password" : "text"}
                className={inputClasses}
                containerClassname="mt-8"
                autoFocus
              />

              <ShowPassButton
                passType={currentPass}
                onClick={() => setCurrentPass(!currentPass)}
              />
            </div>
          )}
        </Modal>
      </SecondaryContainer>
    </>
  );
};
