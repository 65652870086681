import { cx } from "../../utils";
import { Dropdown } from "../dropdown";
import { ThreeDotsIcon } from "../icons/ThreeDotsIcon";
import { ShowMoreText } from "./ShowMoreText";

export interface ILimitedTagsDropdown {
  tagLimit: number;
  className?: string;
  tags: string[];
}

interface ITag {
  className?: string;
  text: string;
  showMoreBtn?: boolean;
}

export const LimitedTagsDropdown: React.FC<ILimitedTagsDropdown> = ({
  className,
  tagLimit,
  tags,
}) => {
  const Tag: React.FC<ITag> = ({ className, text, showMoreBtn }) => {
    return (
      <div
        className={cx([
          "py-1 px-2 rounded-full border border-primary-stroke break-all text-sm whitespace-nowrap text-primary-mainText",
          className,
        ])}
      >
        {showMoreBtn ? <ShowMoreText text={text} /> : text}
      </div>
    );
  };

  return (
    <div className={cx(["flex", className])}>
      <div className="flex">
        {tags?.slice(0, tagLimit).map((item, index) => (
          <Tag text={item} className="mr-1" key={index} />
        ))}
      </div>
      <Dropdown
        left
        dropdownContent={
          <>
            {tags?.slice(tagLimit, tags?.length).map((item, index) => (
              <Tag text={item} key={index} className="mb-2 last:mb-0" />
            ))}
          </>
        }
      >
        <>
          {tagLimit < tags?.length && (
            <button className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center border hover:border-gray-400 focus:shadow-outlinePrimary focus:outline-none">
              <div>
                <ThreeDotsIcon width={18} height={8} />
              </div>
            </button>
          )}
        </>
      </Dropdown>
    </div>
  );
};
