import { FC } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";
import { cx } from "../../utils";

interface ILoginProvider {
  iconName: string;
  name: string;
  email?: string;
  selected?: boolean;
  onButtonClick: () => void;
  lastChild?: boolean;
  hasOneProvider?: boolean;
  hasPassword?: boolean;
  firstChild?: boolean;
}

export const LoginProvider: FC<ILoginProvider> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={cx([
          "flex justify-between py-6",
          !props.firstChild && "border-t border-primary-stroke",
        ])}
      >
        <div className="flex items-start">
          <img
            src={`images/socials/${props.iconName}-logo.svg`}
            alt=""
            width={24}
          />
          <div className="ml-6">
            <h6 className="font-medium text-black">{props.name}</h6>
            {Boolean(props.email) && (
              <p className="text-sm text-primary-secText">{props.email}</p>
            )}

            {(!props.hasOneProvider || props.hasPassword) && (
              <button
                onClick={props.onButtonClick}
                className={cx([
                  "text-xs mt-3 hover:underline",
                  props.selected ? "text-warning" : "text-primary",
                ])}
              >
                {props.selected
                  ? t<string>("homePage.disconnect")
                  : t<string>("homePage.connect")}
              </button>
            )}
          </div>
        </div>

        {props.selected && (
          <div className="text-confirm text-sm uppercase flex items-center">
            <>
              <div className="mr-1">
                <Icon name="Checkmark" size={20} />
              </div>
              {t("externalLogins.added")}
            </>
          </div>
        )}
      </div>
    </>
  );
};
