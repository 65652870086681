import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Loader, Modal, ModalSize } from "../../components";
import { DeviceListItem } from "../../components/security/DeviceListItem";
import { Paragraph } from "../../components/terms";
import { SecondaryContainer } from "../../containers";
import { useFetch, useToggle } from "../../hooks";
import { addPageViewEvent } from "../../utils";

export interface IDevice {
  sessionId: string;
  deviceType: string;
  browser: string;
  currentSession: boolean;
  operatingSystem: string;
  lastActivity: string;
}

export const ManageDevices: FC = () => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<IDevice[]>();
  const [device, setDevice] = useState<IDevice>();
  const { toggle, visible } = useToggle();
  const {
    apiCall: getDevices,
    response,
    loading: getDevicesLoading,
  } = useFetch("get");
  const { apiCall: signoOutFromDevice, loading } = useFetch("post");

  useEffect(() => {
    getDevices("Device/GetDeviceSessionsForUser");
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDevices(response?.activeDeviceSessions);
  }, [response]);

  const onSignout = () => {
    signoOutFromDevice(
      `Device/SignOutFromDevice?sessionId=${device?.sessionId}`,
      {},
      () => {
        const filteredDevices = devices?.filter(
          (deviceItem) => deviceItem.sessionId !== device?.sessionId
        );
        setDevices(filteredDevices);

        toast.success(t("manageDevices.successfullyLoggedOut"));
        toggle();
      }
    );
  };

  return (
    <>
      <SecondaryContainer
        title={t("manageDevices.title")}
        description={t("manageDevices.description")}
        to="/security"
      >
        <div className="sm:pl-16 sm:mt-0 mt-8">
          <h6 className="font-medium text-primary-mainText">
            {t("manageDevices.whereSignedIn")}
          </h6>

          {getDevicesLoading ? (
            <Loader />
          ) : (
            <ul>
              {devices?.map((device: IDevice, index: number) => {
                const lastChild = index === devices?.length - 1;
                return (
                  <DeviceListItem
                    key={device.sessionId}
                    device={device}
                    lastChild={lastChild}
                    onSignOut={() => {
                      setDevice(device);
                      toggle();
                    }}
                  />
                );
              }) || <p>Something went wrong.</p>}
            </ul>
          )}

          <div className="border-t border-primary-stroke sm:pt-16 pt-8 sm:mt-10">
            <h6 className="font-medium text-primary-mainText mb-3">
              {t("manageDevices.whereSignedOut")}
            </h6>
            <Paragraph text={t("manageDevices.youHaveNotSignOut")} noMargin />
          </div>
        </div>
      </SecondaryContainer>

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title={`${t("manageDevices.signedOutOn")} ${device?.deviceType} ${t(
            "manageDevices.device"
          )}}?`}
          withFooter
          onConfirmClick={onSignout}
          confirmBtnText={t("manageDevices.signOut")}
          onCancel={toggle}
          disabled={loading}
          blockOutsideClick
        >
          <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
            {t("manageDevices.signOutFromDeviceWarning")} {device?.deviceType}
            {t("manageDevices.device")}.
          </p>
        </Modal>
      )}
    </>
  );
};
