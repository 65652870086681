import { FC } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal, ModalSize } from "../modal";
import { cx, inputClasses } from "../../utils";
import { useFetch } from "../../hooks";
import { IRecoveryEmailResponse } from "../../pages/security/RecoveryEmail";
import { Input, InputSize, Spinner, SpinnerSize } from "..";

interface IRecoveryEmailModal {
  visible: boolean;
  hide: () => void;
  data: IRecoveryEmailResponse;
  setRecoveryEmailData: any;
  setRecoveryEmail: any;
}

export const RecoveryEmailModal: FC<IRecoveryEmailModal> = ({
  visible,
  hide,
  data,
  setRecoveryEmail,
  setRecoveryEmailData,
}) => {
  const { t } = useTranslation();
  const { apiCall: confirmRecoveryEmail, loading } = useFetch("post");
  const { apiCall: recoveryEmailResend, loading: recoveryEmailResendLoading } =
    useFetch("post");

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onConfirmRecoveryEmail = (allData: any) => {
    const dataObj = {
      ...data,
      otpCode: allData.code,
    };
    confirmRecoveryEmail(
      `ManageApi/VerifyRecoveryEmail`,
      dataObj,
      (data) => {
        toast.success(t<string>("recoverEmail.recoveryEmailSetupSuccessfully"));
        hide();
        reset();
        setRecoveryEmail(data);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const onRecoveryEmailResend = () => {
    const recoveryEmailObj = {
      email: data.email,
    };
    recoveryEmailResend(
      `ManageApi/AddRecoveryEmail`,
      recoveryEmailObj,
      (data) => {
        setRecoveryEmailData(data);
        toast.success(t<string>("recoverEmail.recoveryCodeResent"));
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  return (
    <Modal
      visible={visible}
      hide={hide}
      modalSize={ModalSize.md}
      title={t("recoverEmail.confirmRecoveryEmailTitle")}
      withFooter
      onFormSubmit={handleSubmit(onConfirmRecoveryEmail)}
      confirmBtnText={t("recoverEmail.verify")}
      disabled={loading}
      blockOutsideClick
      confirmBtnType="submit"
    >
      <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
        {t<string>("recoverEmail.confirmRecoveryEmailLabel")}{" "}
        <span className="font-bold">{data?.email}</span>.{" "}
      </p>

      <div className="mb-8">
        <Input
          {...register("code", {
            required: `${t("recoverEmail.verificationCodeRequired")}`,
          })}
          labelProps={{
            children: `${t("recoverEmail.verificationCode")}`,
            className: "text-neutral block pb-0 mb-1.5",
          }}
          className={inputClasses}
          error={!!errors.code && errors.code.message}
          inputSize={InputSize.sm}
          maxLength={6}
          autoFocus
        />
      </div>

      <div className="text-sm text-gray-700 flex items-center">
        {t<string>("recoverEmail.didntGetIt")}{" "}
        <div className="relative inline-flex items-center">
          <button
            className={cx([
              "text-primary text-sm hover:underline ml-1 relative",
              recoveryEmailResendLoading && "opacity-80 pointer-events-none",
            ])}
            type="button"
            onClick={onRecoveryEmailResend}
            disabled={recoveryEmailResendLoading}
          >
            {t<string>("recoverEmail.resend")}
          </button>

          {recoveryEmailResendLoading && (
            <Spinner
              size={SpinnerSize.xs}
              color="text-primary"
              className="ml-1"
              padding="p-0"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
