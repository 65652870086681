import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RadioInput } from "../form/RadioInput";

export const GenderSection: FC<{ gender: string }> = ({ gender }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  return (
    <div className="relative flex sm:flex-row flex-col">
      <p className="text-black flex-shrink-0 top-0 sm:w-48 relative sm:mb-0 mb-6">
        {`${t("profile.gender")}`}
      </p>
      <div className="relative flex-1 flex">
        <RadioInput
          id="female"
          value="female"
          {...register("gender")}
          defaultChecked={gender === "Female"}
        >
          {`${t("profile.female")}`}
        </RadioInput>

        <RadioInput
          id="male"
          value="male"
          {...register("gender")}
          defaultChecked={gender === "Male"}
        >
          {`${t("profile.male")}`}
        </RadioInput>

        <RadioInput
          id="not-say"
          value="rather not say"
          {...register("gender")}
          defaultChecked={gender === "rather not say"}
        >
          {`${t("profile.noGender")}`}
        </RadioInput>
      </div>
    </div>
  );
};
