import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Input, InputSize, ShowPassButton, Animation } from "..";
import { useUserContext } from "../../context";
import { cx, inputClasses } from "../../utils";

interface IPasswordField {
  register: any;
  passwordSettings: {
    requiredLength: number;
    requireDigit: string;
    requireLowercase: string;
    requireNonAlphanumeric: string;
    requireUppercase: string;
    canNotContainEmail: string;
    canNotContainFirstName: string;
    canNotContainLastName: string;
  };
  watch: any;
  errors: any;
}

interface IPwdCriteria {
  valid: boolean;
  notValid: boolean;
  message: string;
}

export const PasswordField: FC<IPasswordField> = ({
  register,
  passwordSettings,
  watch,
  errors,
}) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [newPass, setNewPass] = useState<boolean>(true);
  const {
    requiredLength,
    requireDigit,
    requireLowercase,
    requireNonAlphanumeric,
    requireUppercase,
    canNotContainEmail,
    canNotContainFirstName,
    canNotContainLastName,
  } = !!passwordSettings && passwordSettings;

  const itContainsString = (valueFrom: string, valueAt: string) => {
    return !(valueFrom?.toLowerCase().indexOf(valueAt?.toLowerCase()) >= 0);
  };

  const hasNonAlphaNumeric = (value: string) => {
    return /([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/.test(
      value
    );
  };

  const hasMinOneDigit = (value: string) => {
    return /\d/.test(value);
  };

  const hasMinOneUpperCase = (value: string) => {
    return /^(?=.*[A-Z])/.test(value);
  };

  const hasMinOneLowerCase = (value: string) => {
    return /^(?=.*[a-z])/.test(value);
  };

  const newPwdValue = watch("newPassword");

  const hasMinLength = Boolean(requiredLength)
    ? newPwdValue?.length >= requiredLength
    : true;
  const hasOneDigit = requireDigit ? hasMinOneDigit(newPwdValue) : true;
  const hasUpperCase = requireUppercase
    ? hasMinOneUpperCase(newPwdValue)
    : true;
  const hasLowerCase = requireLowercase
    ? hasMinOneLowerCase(newPwdValue)
    : true;
  const hasAlphaNumeric = requireNonAlphanumeric
    ? hasNonAlphaNumeric(newPwdValue)
    : true;
  const containFirstName = canNotContainFirstName
    ? itContainsString(newPwdValue, user?.firstName)
    : true;
  const containLastName = canNotContainLastName
    ? itContainsString(newPwdValue, user?.lastName)
    : true;
  const containEmail = canNotContainEmail
    ? itContainsString(newPwdValue, user?.email)
    : true;

  const allCriteriaAreValid =
    hasMinLength &&
    hasOneDigit &&
    hasUpperCase &&
    hasLowerCase &&
    hasAlphaNumeric &&
    containFirstName &&
    containLastName &&
    containEmail;

  const PwdCriteria: FC<IPwdCriteria> = ({ valid, notValid, message }) => {
    return (
      <li
        className={cx([
          "relative pl-6 text-primary-secText text-sm flex items-center mb-2",
          valid && "text-green",
          errors?.newPassword && "text-warning",
        ])}
      >
        <span className="absolute top-0 left-0">
          <Icon name={notValid ? "Close" : "Check"} size={20} />
        </span>
        {message}
      </li>
    );
  };

  return (
    <>
      <div className="relative">
        <Input
          {...register("newPassword", {
            required: `${t("changePassword.newPasswordRequired")}`,
            minLength: {
              value: requiredLength || 8,
            },

            validate: {
              nonAlphaNumeric: () => hasAlphaNumeric,
              minOneDigit: () => hasOneDigit,
              minOneUppercase: () => hasUpperCase,
              minOneLowercase: () => hasLowerCase,
              containFirstName: () => containFirstName,
              containLastName: () => containLastName,
              containEmail: () => containEmail,
            },
          })}
          maxLength={150}
          labelProps={{
            children: t("changePassword.newPassword"),
            className: "text-neutral block pb-0 mb-1.5",
          }}
          error={!!errors.newPassword && errors.newPassword.message}
          inputSize={InputSize.sm}
          type={newPass ? "password" : "text"}
          className={cx([
            inputClasses,
            Boolean(newPwdValue?.length) && !allCriteriaAreValid && "pr-14",
            newPwdValue?.length && !allCriteriaAreValid && "sm:border-warning",
          ])}
        />

        {Boolean(newPwdValue?.length) && allCriteriaAreValid && (
          <div className="text-green absolute top-10 right-8">
            <Icon name="Check" size={20} />
          </div>
        )}

        <ShowPassButton
          passType={newPass}
          onClick={() => setNewPass(!newPass)}
        />
      </div>

      <Animation
        type="fade-top"
        show={newPwdValue?.length && !allCriteriaAreValid}
      >
        <div className="mt-6">
          <h6 className="text-primary-secText font-medium text-sm mb-2">
            {t<string>("changePassword.passwordCriteriaTitle")}
          </h6>

          {Boolean(requiredLength) && (
            <PwdCriteria
              valid={newPwdValue?.length >= requiredLength}
              notValid={
                errors?.newPassword && !(newPwdValue?.length >= requiredLength)
              }
              message={t(`passwordStrength.passwordLength`, {
                nrOfCharacters: requiredLength,
              })}
            />
          )}

          {requireNonAlphanumeric && (
            <PwdCriteria
              valid={hasNonAlphaNumeric(newPwdValue)}
              notValid={errors?.newPassword && !hasNonAlphaNumeric(newPwdValue)}
              message={t("passwordStrength.passwordRequiresSpecialCharacter")}
            />
          )}

          {requireDigit && (
            <PwdCriteria
              valid={hasMinOneDigit(newPwdValue)}
              notValid={errors?.newPassword && !hasMinOneDigit(newPwdValue)}
              message={t("passwordStrength.passwordRequiresDigit")}
            />
          )}

          {requireUppercase && (
            <PwdCriteria
              valid={hasMinOneUpperCase(newPwdValue)}
              notValid={errors?.newPassword && !hasMinOneUpperCase(newPwdValue)}
              message={t("passwordStrength.passwordRequiresUppercase")}
            />
          )}

          {requireLowercase && (
            <PwdCriteria
              valid={hasMinOneLowerCase(newPwdValue)}
              notValid={errors?.newPassword && !hasMinOneLowerCase(newPwdValue)}
              message={t("passwordStrength.passwordRequiresLowercase")}
            />
          )}

          {canNotContainFirstName && !!user?.firstName && (
            <PwdCriteria
              valid={itContainsString(newPwdValue, user?.firstName)}
              notValid={
                errors?.newPassword &&
                !itContainsString(newPwdValue, user?.firstName)
              }
              message={t("passwordStrength.passwordCannotContainFirstName")}
            />
          )}

          {canNotContainLastName && !!user?.lastName && (
            <PwdCriteria
              valid={itContainsString(newPwdValue, user?.lastName)}
              notValid={
                errors?.newPassword &&
                !itContainsString(newPwdValue, user?.lastName)
              }
              message={t("passwordStrength.passwordCannotContainLastName")}
            />
          )}

          {canNotContainEmail && (
            <PwdCriteria
              valid={itContainsString(newPwdValue, user?.email)}
              notValid={
                errors?.newPassword &&
                !itContainsString(newPwdValue, user?.email)
              }
              message={t("passwordStrength.poasswordCannotContainEmail")}
            />
          )}
        </div>
      </Animation>
    </>
  );
};
