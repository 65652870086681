import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { SecondaryContainer } from "../../containers";
import { addPageViewEvent, copyToClipboard } from "../../utils";
import { Warning } from "../../components";

export const RecoveryCodes: FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation() as any;
  const recoveryCodes = state?.codes;
  const recoveryCodesLength = state?.codes?.length;

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa.genereate2faRecoveryCodes")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("2fa.genereate2faRecoveryCodes")}
        description={t("2fa.generationOfNewRecoveryCodes")}
      >
        <div className="shadow-card-md rounded-xl border border-gray-200 sm:p-6 p-4 flex justify-between items-center mt-4 sm:mt-0">
          <div className="flex items-baseline">
            <Warning className="top-0.5 relative mr-5" />

            <div className="sm:w-9/12">
              <h6 className="font-medium text-black mb-1">
                {t<string>("2fa.putCodesInSafePlace")}
              </h6>
              <p className="text-sm text-gray-700">
                {t<string>("2fa.recoveryCodesInfo")}
              </p>
            </div>
          </div>
        </div>

        <div className="sm:pl-16">
          <p className="text-sm mt-8">{t<string>("2fa.recoveryCodesInfo2")}.</p>
        </div>

        <div className="sm:w-5/12 rounded-lg p-6 border border-gray-300 mx-auto mt-8">
          <div className="pb-6 border-b border-gray-300 font-medium text-gray-700">
            {t<string>("2fa.save2faRecoveryCodes")}
          </div>

          <div className="py-8 flex items-center justify-center">
            <div className="flex flex-col mr-3">
              {recoveryCodes
                ?.slice(0, recoveryCodesLength / 2)
                .map((code: string) => (
                  <span className="mb-3">{code}</span>
                ))}
            </div>

            <div className="flex flex-col ml-3">
              {recoveryCodes
                ?.slice(recoveryCodesLength / 2, recoveryCodesLength)
                .map((code: string) => (
                  <span className="mb-3">{code}</span>
                ))}
            </div>
          </div>

          <button
            className="border border-gray-300 py-3 flex px-3 rounded w-full items-center justify-center text-neutral hover:bg-gray-100 transition"
            type="button"
            onClick={() =>
              copyToClipboard(
                state?.codes,
                `${t("2fa.recoveryCodesGeneratedSuccessfully")}!`
              )
            }
          >
            <div className="mr-2">
              <Icon name="Copy" size={20} />
            </div>
            {t<string>("2fa.copy")}
          </button>
        </div>
      </SecondaryContainer>
    </>
  );
};
