import { FC, useEffect } from "react";

import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { Loader, Modal, ModalSize } from "../../components";
import { Desktop, Location, Phone } from "../../components/icons";
import { CurrentDeviceLabel, MessageAlert } from "../../components/security";
import { SecondaryContainer } from "../../containers";
import { useFetch, useToggle } from "../../hooks";
import { addPageViewEvent, formatDate, MANAGE_DEVICES_PATH } from "../../utils";
import { useTranslation } from "react-i18next";

export const DeviceDetail: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { toggle, visible } = useToggle();
  const {
    apiCall: getDeviceDetails,
    response: device,
    loading: deviceDetailsLoading,
  } = useFetch("get");
  const { apiCall: signoOutFromDevice, loading } = useFetch("post");

  useEffect(() => {
    getDeviceDetails(
      `Device/GetDeviceSessionDetailsBySessionId?sessionId=${sessionId}`
    );
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignout = () => {
    signoOutFromDevice(
      `Device/SignOutFromDevice?sessionId=${sessionId}`,
      {},
      () => {
        toast.success(t("manageDevices.successfullyLoggedOut"));
        toggle();
        navigate(MANAGE_DEVICES_PATH);
      }
    );
  };

  if (deviceDetailsLoading) {
    return <Loader />;
  }

  return (
    <SecondaryContainer
      title={t("manageDevices.title")}
      to={MANAGE_DEVICES_PATH}
      headlineContent={
        <MessageAlert
          message={`${
            device?.deviceType === "Desktop" ? "Desktop" : "Phone"
          } ${t("manageDevices.deviceSignedSince")} ${
            device?.created
              ? formatDate(new Date(device?.created))
              : t("manageDevices.unknownDate")
          }`}
          onClick={toggle}
          buttonText={t("manageDevices.signOut")}
          noButton={device?.currentSession}
        />
      }
    >
      <div className="sm:pl-16 relative text-primary-mainText">
        <div className="sm:absolute left-0 top-2  sm:mt-0 sm:mb-0 mt-6 mb-2">
          {device?.deviceType === "Desktop" ? <Desktop /> : <Phone />}
        </div>
        <div className="pl-15">
          <h6 className="font-medium text-primary-mainText mb-3 capitalize">
            {device?.deviceType}
          </h6>
          <p className="mb-2">
            {device?.currentSession ? (
              <CurrentDeviceLabel text={t("manageDevices.thisDevice")} />
            ) : (
              device?.lastActivity && moment(device?.lastActivity).fromNow()
            )}
          </p>
          <p className="mb-2 ">
            {"manageDevices.firstSignIn"}:{" "}
            {device?.created
              ? formatDate(new Date(device?.created))
              : t("manageDevices.unknownDate")}
          </p>
          <p>OS: {device?.operatingSystem}</p>

          <div className="my-6 border-t border-primary-stroke" />

          <div className="">
            <p className="text-sm text-primary-secText mb-6">
              {t("manageDevices.recentActivity")}
            </p>

            <div className="flex items-center mb-2">
              <span className="text-primary-secText">
                <Location />
              </span>
              <p className="ml-4">{device?.ipAddressLocation}</p>
              <p className="text-sm text-primary-secText sm:ml-10">
                {" "}
                {device?.lastActivity && moment(device?.lastActivity).fromNow()}
              </p>
            </div>
          </div>

          <div className="my-6 border-t border-primary-stroke" />

          <div className="sm:mt-0 mt-4">
            <p className="text-sm text-primary-secText mb-6">
              {t("manageDevices.signedInBrowser")}
            </p>
            <div className="flex items-center">
              <div className="w-6 h-6 flex items-center justify-center rounded mr-3">
                <img
                  src={`/images/browsers/${device?.browser?.toLowerCase()}.png`}
                  alt={`${device?.browser} Logo`}
                  className="w-full h-full"
                />
              </div>
              {device?.browser || ""}
            </div>
          </div>
        </div>
      </div>

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title={`${t("manageDevices.signedOutOn")} Phone ${t(
            "manageDevices.device"
          )}`}
          withFooter
          onConfirmClick={onSignout}
          confirmBtnText={t("manageDevices.signOut")}
          onCancel={toggle}
          disabled={loading}
          blockOutsideClick
        >
          <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
            {t("manageDevices.signOutFromDeviceWarning")} {device?.deviceType} t
            {"manageDevices.device"}.
          </p>
        </Modal>
      )}
    </SecondaryContainer>
  );
};
