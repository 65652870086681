import { FC } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { RedirectSection } from "./RedirectSection";

interface IPasswordSection {
  hasPassword: boolean;
  passwordLastChanged: string;
}

export const PasswordSection: FC<IPasswordSection> = ({
  hasPassword,
  passwordLastChanged,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative flex sm:flex-row flex-col">
      <p className="text-black flex-shrink-0 sm:top-7 w-48 relative">
        {`${t("profile.password")}`}
      </p>
      <div className="relative flex-1">
        <RedirectSection
          to="/change-password"
          label={
            hasPassword
              ? `${t("profile.lastChanged")}: ${
                  !!passwordLastChanged
                    ? format(
                        new Date(passwordLastChanged),
                        "dd/MM/yyyy, hh:mm:ss"
                      )
                    : t("profile.never")
                }`
              : t("profile.setPassword")
          }
        />
      </div>
    </div>
  );
};
