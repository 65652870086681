import { FC } from "react";

import { NavLink } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface IRedirectSection {
  label: string | undefined;
  to: string;
}

export const RedirectSection: FC<IRedirectSection> = ({ label, to }) => {
  return (
    <NavLink
      to={to}
      className={cx([
        "flex place-center justify-between flex-1 py-8 text-neutral",
        "hover:text-primary",
      ])}
    >
      <p className="text-sm">{label}</p>
      <div>
        <Icon name="ArrowRight" size={20} />
      </div>
    </NavLink>
  );
};
