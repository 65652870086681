import { FC, useEffect } from "react";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { Initials, PageInfoItem, RoundedImg } from "../../components";
import { SectionLoader } from "../../components/account";
import { MainContainer } from "../../containers";
import { useUserContext } from "../../context";
import { useFetch } from "../../hooks";
import { addPageViewEvent, featureEnabled } from "../../utils";

export const MyAccount: FC = () => {
  const { t } = useTranslation();

  const auth = useAuth();
  const { user, loading: userLoading, picture } = useUserContext();
  const userId = auth?.userData?.profile?.sub || "";
  const {
    apiCall: getExternalProviders,
    response: providers,
    loading,
  } = useFetch("get");

  useEffect(() => {
    getExternalProviders(`Auth/ExternalLogins?userId=${userId}`);

    addPageViewEvent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentLogins = providers?.currentLogins;

  const userLoginProviders = currentLogins?.map(
    (provider: {
      loginProvider: string;
      providerDisplayName: string;
      providerKey: string;
    }) => {
      return " " + provider?.loginProvider;
    }
  );

  return (
    <>
      <Helmet>
        <title>{t<string>("homePage.myAccount")}</title>
      </Helmet>
      <MainContainer>
        <div className="flex flex-1 flex-col items-center">
          {!!picture ? (
            <RoundedImg
              src={picture}
              alt="user"
              className="w-20 h-20 sm:w-36 sm:h-36"
            />
          ) : (
            <Initials text={user?.firstName || user?.email} />
          )}

          <div className="mt-8 text-center">
            <h3 className="text-black">
              {t("homePage.welcome")},{" "}
              {!!user?.firstName && !!user.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.email}
            </h3>
            <p className="text-neutral mb-2 mt-2">
              {t<string>("homePage.manageInfoTitle")}
            </p>
          </div>
        </div>

        <div className="mt-14">
          {(userLoading || loading) && <SectionLoader itemsCount={2} />}

          {!userLoading && !loading && (
            <>
              {featureEnabled["profileInfo"] === true && (
                <PageInfoItem
                  title={t("homePage.profileInformation")}
                  description={
                    <>
                      <p className="text-sm">
                        {t<string>("homePage.editProfileInfo")}
                      </p>

                      {!!user?.firstName && !!user?.lastName ? null : (
                        <span className="inline-flex bg-primary-100 p-2 rounded text-primary-mainText text-xs mt-4">
                          {t<string>("homePage.completeProfileInfo")}
                        </span>
                      )}
                    </>
                  }
                  iconName="account-info.png"
                  to="/profile"
                />
              )}

              {featureEnabled["connectedAccounts"] === true && (
                <PageInfoItem
                  title={t("homePage.myConnectedAccounts")}
                  description={
                    currentLogins?.length
                      ? `${t(
                          "homePage.connectedAccounts"
                        )}: ${userLoginProviders}.`
                      : `${t("homePage.noConnectedAccounts")}`
                  }
                  iconName="external-login.png"
                  to="/external-logins"
                  lastChild
                />
              )}
            </>
          )}
        </div>
      </MainContainer>
    </>
  );
};
