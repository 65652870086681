import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header as HeaderLayout } from "./";

import { Dropdown, RoundedImg } from "../";
import {
  onlyForRoles,
  useOrganizationSettingsContext,
  useUserContext,
} from "../../context";
import { useFetch } from "../../hooks";
import { Permission, getPostLoginUri, getUserInfo } from "../../utils";
import { InitialSize, Initials } from "../ui/Initials";
import { ApplicationsDropdown } from "./ApplicationsDropdown";
import { EmailNotVerifiedWarning } from "./EmailNotVerifiedWarning";
import SettingsDropdownWrapper from "./SettingsDropdownWrapper";
import { ILanguage } from "./interface";

export const Header = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { admin, appsEditor, usersEditor } = Permission;

  const auth = useAuth();
  const { user, picture } = useUserContext();
  const { organization } = useOrganizationSettingsContext();
  const { i18n } = useTranslation();

  const { apiCall: getLanguages, response: languages } = useFetch("get");
  const { apiCall: getDefaultLanguage, response: defaultLang } =
    useFetch("get");
  const { apiCall: setLanguage } = useFetch("post");

  const { firstName, lastName, email, isEmailConfirmed } = !!user && user;

  const [currentLangObj, setCurrentLangObj] = useState<any>();
  const {
    profile: { language_code },
  } = getUserInfo();

  useEffect(() => {
    getLanguages("Localization/SelectedLanguages");
    getDefaultLanguage("Localization/DefaultLanguage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentCode =
      localStorage.getItem("lang") !== "undefined"
        ? localStorage.getItem("lang")
        : null;

    const userSelectedLang = languages?.find(
      (lang: ILanguage) =>
        (currentCode || language_code || defaultLang?.languageCode) ===
        lang.languageCode
    );

    setCurrentLangObj(userSelectedLang);

    !Boolean(currentCode) &&
      localStorage.setItem("lang", language_code || defaultLang?.languageCode);

    i18n.changeLanguage(
      currentCode || language_code || defaultLang?.languageCode
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language_code, languages, defaultLang]);

  const changeLanguage = (code: string, lang: ILanguage) => {
    i18n.changeLanguage(code);
    setCurrentLangObj(lang);
    localStorage.setItem("lang", code);
    setLanguage(`Localization/ChangeLanguage?languageCode=${code}`);
  };

  const settingsDropdownVisiblity = (visiblity: boolean) => {
    setVisible(visiblity);
  };

  const addGoogleEvent = () => {
    (window as any).dataLayer.push({
      event: "user_logout_start",
    });
  };

  return (
    <>
      <HeaderLayout
        headerClass="shadow-none py-4 sm:pr-0 pr-4 pl-4"
        logo={
          <a href="https://fameplay.tv">
            <img
              src={organization?.organizationLogo || "/images/porta-logo.png"}
              alt="iam logo"
              className="h-10 w-auto"
            />
          </a>
        }
      >
        <div className="flex justify-end flex-grow">
          <div className="flex items-center">
            <ApplicationsDropdown />

            <Dropdown
              width="w-72"
              noPadding
              dropdownVisibility={settingsDropdownVisiblity}
              dropdownContent={
                <SettingsDropdownWrapper
                  user={{
                    name:
                      !!user?.firstName && !!user?.lastName
                        ? `${firstName} ${lastName}`
                        : "",
                    email: email,
                    picture,
                    isAdmin: onlyForRoles([admin, appsEditor, usersEditor]),
                  }}
                  onSignOut={() => {
                    auth?.signOutRedirect();
                    getPostLoginUri(false);
                    addGoogleEvent();
                  }}
                  changeLanguage={changeLanguage}
                  currentLangObj={currentLangObj}
                  languages={languages}
                />
              }
            >
              <div
                className={`rounded-full hover:shadow-outlineGray transition cursor-pointer ${
                  visible && "shadow-outlineGray"
                }`}
                title={
                  !!user?.firstName && !!user?.lastName ? firstName : email
                }
              >
                {!!picture ? (
                  <RoundedImg
                    src={picture}
                    alt="user"
                    className="cursor-pointer"
                  />
                ) : (
                  <Initials
                    text={
                      !!user?.firstName && !!user?.lastName ? firstName : email
                    }
                    initialSize={InitialSize.sm}
                  />
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      </HeaderLayout>

      {isEmailConfirmed === false && <EmailNotVerifiedWarning />}
    </>
  );
};
