export const TFA_PATH = "/two-factor-authentication";

export const TFA_TEXT_MESSAGE_PATH = `${TFA_PATH}/text-message`;
export const TFA_EMAIL_PATH = `${TFA_PATH}/email-authenticator`;
export const TFA_AUTH_APP_PATH = `${TFA_PATH}/authenticator-app`;
export const TFA_RECOVERY_CODES_PATH = `${TFA_PATH}/recovery-codes`;

export const SUBSCRIPTIONS_PATH = `/subscriptions`;
export const SUBSCRIPTIONS_LIST_PATH = `${SUBSCRIPTIONS_PATH}/list`;

export const PAYMENTS_PATH = `/payments`;
export const PAYMENTS_LIST_PATH = `${PAYMENTS_PATH}/list`;

export const GIFTS_PATH = `/gifts-and-vouchers`;
export const GIFTS_PATH_LIST = `${GIFTS_PATH}/list`;

export const SITE_LICENCES_PATH = `/site-licences`;
export const SITE_LICENCE_USERS_PATH = `/site-licence/users`;

export const RECOVERY_EMAIL_PATH = "/recovery-email"

export const MANAGE_DEVICES_PATH = "/manage-devices"
export const DEVICE_DETAIL_PATH = `${MANAGE_DEVICES_PATH}/detail`

export const MANAGE_PERMISSIONS_PATH = "/permissions"

