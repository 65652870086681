import { FC, ReactNode } from "react";
import { Divider, FormFooter } from "..";
import { IFormFooter } from "../../interfaces";

interface IFormContainer extends IFormFooter {
  children: ReactNode;
  onSubmit?: () => void;
}

export const FormContainer: FC<IFormContainer> = (props) => {
  return (
    <form onSubmit={props.onSubmit} noValidate>
      <div className="sm:px-4 md:px-0 sm:pt-0 pt-4">{props.children}</div>

      <Divider className="mb-12 sm:mb-8" />

      <FormFooter {...props} />
    </form>
  );
};
