import { SecondaryContainer } from "../../containers";

import { useEffect, useState } from "react";

import { format } from "date-fns";
import { toast } from "react-toastify";
import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import {
  Avatar,
  AvatarRounded,
  AvatarSize,
  Loader,
  Modal,
  ModalSize,
  Table,
  Td,
} from "../../components";
import { addPageViewEvent, getUserInfo } from "../../utils";
import { LimitedTagsDropdown } from "../../components/ui/LimitedTagsDropdown";
import { useTranslation } from "react-i18next";

interface IGrant {
  apiGrantNames: string[];
  clientId: string;
  clientLogoUrl: string;
  clientName: string;
  clientUrl: string;
  created: string;
  description: string;
  expires: string;
  identityGrantNames: string[];
}

export const Permissions = () => {
  const { t } = useTranslation();
  const {
    profile: { sub },
  } = getUserInfo();

  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();

  const PER_PAGE = 10;

  const [grants, setGrants] = useState<IGrant[]>();
  const [grant, setGrant] = useState<IGrant>();

  const { apiCall: getUserGrants, response, loading } = useFetch("get");
  const { apiCall: revokeAccess, loading: revokeAccessLoading } =
    useFetch("post");

  useEffect(() => {
    getUserGrants(
      `PersistedGrants/GetUserGrants?userId=${sub}&page=${currentPage}&pageSize=${PER_PAGE}`
    );
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGrants(response?.grants);
  }, [response]);

  const onRevokeAccess = () => {
    revokeAccess(
      `PersistedGrants/RevokeByUser?clientId=${grant?.clientId}&userId=${sub}`,
      {},
      () => {
        toast.success(t("permissions.accessRevokedSuccessfully"));
        toggle();
        const filteredGrants = grants?.filter(
          (grantItem) => grantItem.clientId !== grant?.clientId
        );
        setGrants(filteredGrants);
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <SecondaryContainer
      title={t("security.permissions")}
      description={t("security.permissionsDesc")}
    >
      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: t("permissions.name") },
          { children: t("permissions.created") },
          { children: t("permissions.expired") },
          { children: t("permissions.igrants") },
          // { children: "API Grants" },
          { children: "", className: "text-right" },
        ]}
        values={grants || []}
        renderTr={(el) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.clientId}>
              <Td className="py-3">
                <div className="flex items-center">
                  <Avatar
                    imgUrl={el?.clientLogoUrl}
                    text=""
                    size={AvatarSize.sm}
                    rounded={AvatarRounded["rounded-full"]}
                    className="mr-4"
                    colourful
                    index={el?.colorId}
                  />
                  {el?.clientName}
                </div>
              </Td>
              <Td className="relative py-3">
                {el?.created
                  ? format(new Date(el?.created), "MMMM dd, yyyy")
                  : ""}
              </Td>
              <Td className="py-3">
                {el?.created
                  ? format(new Date(el?.expires), "MMMM dd, yyyy")
                  : ""}
              </Td>
              <Td className="py-3">
                <LimitedTagsDropdown
                  tagLimit={1}
                  tags={el?.identityGrantNames}
                />
              </Td>
              {/* <Td className="py-3">
                <LimitedTagsDropdown tagLimit={2} tags={el?.apiGrantNames} />
              </Td> */}
              <Td className="text-primary-secText py-3" align="right">
                <button
                  type="button"
                  onClick={() => {
                    toggle();
                    setGrant(el);
                  }}
                  className="p-2 transition hover:bg-gray-100 rounded focus:outline-none focus:shadow-outlineGray text-warning"
                >
                  {t("permissions.revokeAccess")}
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: 10,
          totalCount: 10,
          onPageChange: handlePageChange,
          page: currentPage,
          length: grants?.length,
        }}
        emptyView={{ text: t("permissions.noGrants") }}
      />

      {!!visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title={t("permissions.modalTitle")}
          onConfirmClick={onRevokeAccess}
          confirmBtnText={t("permissions.confirmBtnText")}
          modalSize={ModalSize.sm}
          withFooter
          disabled={revokeAccessLoading}
          blockOutsideClick
          warningModal
        >
          <p className="text-sm text-primary-mainText mb-4">
            {t("permissions.modalTitle")}
          </p>
        </Modal>
      )}
    </SecondaryContainer>
  );
};
