import { FC } from "react";
import { useTranslation } from "react-i18next";

import { cx, primaryBtnClasses } from "../../utils";
import { Button, BtnStyle, BtnSize } from "../button";

interface IMessageAlert {
  className?: string;
  message: string;
  disabled?: boolean;
  buttonText?: string;
  onClick: () => void;
  noButton?: boolean;
}

export const MessageAlert: FC<IMessageAlert> = ({
  className,
  message,
  onClick,
  disabled,
  buttonText,
  noButton,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx([
        "flex sm:flex-row flex-col items-start justify-between sm:items-center bg-gray-100 py-4 sm:px-8 px-4 mt-6 text-neutral rounded-md animate-fade-in",
        className,
      ])}
    >
      {message}

      {!noButton && (
        <Button
          type="button"
          onClick={onClick}
          btnStyle={BtnStyle.primary}
          btnSize={BtnSize.nomral}
          disabled={disabled}
          className={cx([
            "sm:ml-4 mt-4 sm:mt-0 flex items-center",
            primaryBtnClasses,
          ])}
        >
          {disabled && (
            <img
              src="/images/oval.svg"
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}
          {buttonText || t<string>("2fa.turnOff")}
        </Button>
      )}
    </div>
  );
};
