import { FC } from "react";
import ContentLoader from "react-content-loader";

interface ITfaSectionLoader {
  itemsCount: number;
}

export const TfaSectionLoader: FC<ITfaSectionLoader> = ({ itemsCount }) => {
  //   const size: Size = useWindowSize();
  //   const mobileScreen = !!size.width && size.width < 768;

  return (
    <>
      {[...Array(itemsCount)]?.map((index) => (
        <ContentLoader
          speed={2}
          width={600}
          height={72}
          key={index}
          className="sm:py-8 sm:px-6"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="4" width="400" height="24" />
          <rect x="0" y="36" rx="4" width={528} height="16" />
        </ContentLoader>
      ))}
    </>
  );
};
