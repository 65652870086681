import { FC } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";
import { ButtonProps } from "../button";

interface IStatusMessage extends ButtonProps {
  className?: string;
  title?: string;
  message?: string;
  subTitle?: string;
  icon: string;
  hasVerify?: boolean;
}

export const StatusMessage: FC<IStatusMessage> = ({
  icon,
  title,
  subTitle,
  message,
  hasVerify,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex mt-8 sm:ml-16">
      <div className="mr-8">
        <Icon name={icon} size={24} />
      </div>

      <div>
        <h5>{title}</h5>
        <p className="mt-2">
          {subTitle}

          {hasVerify && (
            <span className="text-sm text-confirm ml-6">
              {t<string>("2fa.verified")}
            </span>
          )}
        </p>
        <p className="mt-6 text-sm text-neutral">{message}</p>
      </div>
    </div>
  );
};
