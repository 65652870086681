import { FC, useEffect, useState } from "react";

import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { SecondaryContainer } from "../../containers";
import { LoginProvider } from "../../components/account";
import { ICurrentLogin, ILoginProvider } from "../../interfaces";
import { useFetch, useHasPasswordApi } from "../../hooks";
import { Loader } from "../../components";
import { addPageViewEvent } from "../../utils";

export const ExternalLogins: FC = () => {
  const { t } = useTranslation();
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const { hasPassword } = useHasPasswordApi(true);
  const { apiCall: removeExternalLogin } = useFetch("post");

  const auth = useAuth();
  const userId = auth?.userData?.profile?.sub || "";
  const {
    apiCall: getExternalProviders,
    response: providers,
    loading,
  } = useFetch("get");

  const onRemoveExternalLogin = async (provider: ILoginProvider) => {
    removeExternalLogin(`/Auth/RemoveLoginApi`, provider, () => {
      setShouldUpdate((current) => !current);
      toast.success(`${t("externalLogins.removedSuccessfully")}!`);
    });
  };

  const setupExternalLogin = (provider: string) => {
    window.location.href = `${process.env.REACT_APP_AUTHORITY}/manage/LinkLoginApi?provider=${provider}`;
  };

  useEffect(() => {
    getExternalProviders(`Auth/ExternalLogins?userId=${userId}`);
  }, [shouldUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("homePage.myConnectedAccounts")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("homePage.myConnectedAccounts")}
        description={t("homePage.connectedAccountsDesc")}
        to="/"
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="mt-6 sm:mt-0">
              <p className="text-black mb-4 font-medium">
                {t<string>("homePage.connected")}
              </p>
              {!!providers?.currentLogins &&
                providers?.currentLogins?.length < 1 && (
                  <p className="text-xs text-primarySecText">
                    {t<string>("homePage.notYetConnected")}
                  </p>
                )}

              {providers?.currentLogins?.map(
                (
                  {
                    providerDisplayName,
                    providerKey,
                    loginProvider,
                  }: ICurrentLogin,
                  index: number
                ) => {
                  const providersLength = providers?.currentLogins?.length;
                  return (
                    <LoginProvider
                      hasOneProvider={providersLength < 2}
                      key={index}
                      firstChild={index === 0}
                      lastChild={providersLength === index - 1}
                      selected
                      name={loginProvider}
                      email={providerDisplayName}
                      hasPassword={hasPassword}
                      onButtonClick={() =>
                        onRemoveExternalLogin({
                          providerKey,
                          loginProvider,
                          userId,
                        })
                      }
                      iconName={loginProvider?.toLocaleLowerCase()}
                    />
                  );
                }
              )}
            </div>

            <div>
              {Boolean(providers?.otherLogins?.length) && (
                <p className="text-black mb-2 sm:mt-16 mt-10 font-medium">
                  {t<string>("homePage.notConnected")}
                </p>
              )}

              {providers?.otherLogins?.map(({ name }: any, index: number) => {
                const isLast = providers?.otherLogins?.length === index - 1;
                return (
                  <LoginProvider
                    key={index}
                    name={name}
                    hasOneProvider={false}
                    hasPassword={false}
                    onButtonClick={() => setupExternalLogin(name)}
                    iconName={name?.toLocaleLowerCase()}
                    firstChild={index === 0}
                    lastChild={isLast}
                  />
                );
              })}
            </div>
          </>
        )}
      </SecondaryContainer>
    </>
  );
};
