import { FC, ChangeEvent, useState, useEffect, FormEvent } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Button, Modal, Spinner, SpinnerSize, RoundedImg } from "..";
import { ModalFooter, ModalSize } from "../modal";
import { PhotoProfileEmpty, PhotoProfileFilled } from ".";
import { useUserContext } from "../../context/UserContext";
import { useFetch } from "../../hooks";
import {
  cancelBtnClasses,
  cx,
  primaryBtnClasses,
  validateImage,
} from "../../utils";
import { BtnStyle, BtnType } from "../button";

interface IPhotoProfileModal {
  toggle: () => void;
  visible: boolean;
}

export const PhotoProfileModal: FC<IPhotoProfileModal> = ({
  toggle,
  visible,
}) => {
  const { t } = useTranslation();
  const { user, picture, setPicture, setUserData } = useUserContext();
  const { apiCall: updateProfilePhoto, loading } = useFetch("post");
  const { apiCall: deleteProfilePhoto, loading: deleteLoading } =
    useFetch("delete");
  const [image, setImage] = useState<any>();
  const [hasFile, setHasFile] = useState<boolean>(false);

  useEffect(() => {
    setImage({
      ...image,
      preview: picture,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onChangePhoto = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      event.target.value = "";
      const validateImageMessage = validateImage(
        file,
        process.env.REACT_APP_PROFILE_IMAGE_FORMAT_ACCEPT || "",
        2097152,
        "1MB"
      );

      if (validateImageMessage?.length) {
        toast.error(validateImageMessage);
      } else {
        setImage(() => ({
          ...image,
          file,
          preview: URL.createObjectURL(file),
          name: file.name,
        }));
        setHasFile(true);
      }
    }
  };

  const onUpdateProfilePhoto = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", image.file);

    updateProfilePhoto(
      "ManageApi/UploadProfilePicture",
      formData,
      (response) => {
        toast.success(`${t("profile.profilePictureUpdatedSuccessfully")}!`);
        setPicture(response);
        setUserData(() => ({
          ...user,
          picture: response,
        }));

        toggle();
      },
      (error) => {
        toast.error(t<string>(error?.response?.data));
      }
    );
  };

  const onDeleteProfilePhoto = () => {
    deleteProfilePhoto("ManageApi/RemoveProfilePicture", {}, () => {
      setImage(null);
      setHasFile(false);
      setPicture(null);
      setUserData(() => ({
        ...user,
        picture: null,
      }));
      toast.success(`${t("profile.profilePictureDeletedSuccessfully")}!`);
    });
  };

  const clearProfilePhoto = () => {
    setImage(null);
    setHasFile(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        hide={toggle}
        modalSize={ModalSize.md}
        title={t("profile.changePhotoProfile")}
        blockOutsideClick
      >
        <form onSubmit={(e) => onUpdateProfilePhoto(e)}>
          {!!image && !picture && hasFile && (
            <PhotoProfileFilled
              imgPath={
                image.preview || "https://source.unsplash.com/user/erondu"
              }
              onChange={onChangePhoto}
              imgName={image.name}
              onClear={clearProfilePhoto}
            />
          )}

          {!picture && !hasFile && (
            <PhotoProfileEmpty onChange={onChangePhoto} value={picture} />
          )}

          {picture && (
            <RoundedImg
              src={picture}
              alt="user"
              className="w-36 h-36 sm:w-52 sm:h-52 mx-auto"
            />
          )}

          <ModalFooter className="justify-between">
            {picture && (
              <Button
                type="button"
                btnType={BtnType.tertiary}
                className={cx([
                  "flex items-center border border-primary-stroke text-blue-secText",
                  deleteLoading && "cursor-not-allowed opacity-60",
                  cancelBtnClasses,
                ])}
                onClick={onDeleteProfilePhoto}
                disabled={deleteLoading}
              >
                <>
                  {deleteLoading && (
                    <Spinner
                      size={SpinnerSize.xs}
                      color="text-warning"
                      className="mr-2"
                      padding="p-0"
                    />
                  )}
                  {t("profile.removePhoto")}
                </>
              </Button>
            )}
            &nbsp;
            <div className="flex">
              <Button
                type="button"
                btnStyle={BtnStyle.neutral}
                btnType={BtnType.secondary}
                className={cancelBtnClasses}
                onClick={() => {
                  toggle();
                }}
              >
                {t<string>("profile.cancel")}
              </Button>

              {!picture && (
                <Button
                  btnType={BtnType.primary}
                  type="submit"
                  className={cx([
                    primaryBtnClasses,
                    "cursor-default ml-4 flex items-center",
                    !image?.preview ||
                      (loading && "cursor-not-allowed opacity-60"),
                  ])}
                  disabled={!image?.preview || loading}
                >
                  {loading && (
                    <Spinner
                      size={SpinnerSize.xs}
                      color="text-white"
                      className="mr-2"
                      padding="p-0"
                    />
                  )}
                  {t<string>("profile.setAsPhotoProfile")}
                </Button>
              )}
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
