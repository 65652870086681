import { FC } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";

import { RoundedImg } from "../";
import { PhotoProfileLabel, IPhotoProfileLabel } from "./PhotoProfileLabel";
import { cx } from "../../utils";

interface IPhotoProfileFilled extends IPhotoProfileLabel {
  onClear?: () => void;
  imgName: string;
  imgPath: string;
}

export const PhotoProfileFilled: FC<IPhotoProfileFilled> = ({
  onClear,
  imgName,
  imgPath,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center leading-5 text-xs">
        <button
          type="button"
          className="text-neutral font-medium text-sm hover:underline cursor-pointer"
          onClick={onClear}
        >
          {t<string>("profile.uploadPhotos")}
        </button>
        <span className="px-2">
          <Icon name="ArrowRight" size={14} />
        </span>
        <p className="text-primary text-sm">{imgName}</p>
      </div>

      <p className="text-neutral text-sm mb-6">
        {t<string>("profile.profileInformation")}
      </p>

      <PhotoProfileLabel {...props}>
        <div className="flex justify-center">
          <div className="relative w-36 h-36 sm:w-52 sm:h-52 group cursor-pointer">
            <RoundedImg
              src={imgPath}
              alt="user"
              className="w-36 h-36 sm:w-52 sm:h-52"
            />

            <div
              className={cx([
                "absolute top-0 left-0",
                "w-full h-full rounded-full bg-black bg-opacity-70 text-white text-sm",
                "flex flex-col items-center justify-center",
                "opacity-0 transition",
                "group-hover:opacity-100",
              ])}
            >
              <>
                {t("profile.changePhotoProfile")}
                <span className="underline">
                  {" "}
                  {t<string>("profile.browse")}
                </span>
              </>
            </div>
          </div>
        </div>
      </PhotoProfileLabel>
    </>
  );
};
