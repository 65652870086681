import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { SecondaryContainer } from "../../containers";
import { Modal, ModalSize, NavLinkWrapper, Warning } from "../../components";
import {
  Indicator,
  NavLinkInner,
  RecoveryCodeCard,
  TfaSectionLoader,
} from "../../components/security";
import { useTfaContext } from "../../context/TfaContext";
import { useFetch, useToggle } from "../../hooks";

import {
  addPageViewEvent,
  featureEnabled,
  TFA_AUTH_APP_PATH,
  TFA_EMAIL_PATH,
  TFA_RECOVERY_CODES_PATH,
  TFA_TEXT_MESSAGE_PATH,
} from "../../utils";

export interface IAuth {
  emailOtpEnabled: boolean;
  hasAuthenticator: boolean;
  is2faEnabled: boolean;
  isMachineRemembered: boolean;
  recoveryCodesLeft: number;
  smsOtpEnabled: boolean;
  status: number;
}

export const TwoFactorAuthentication: FC = () => {
  const { tfa } = useTfaContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggle, visible } = useToggle();

  const {
    apiCall: getMfaSettings,
    response: mfaSettings,
    loading,
  } = useFetch("get");

  const { apiCall: getRecoveryCodes, loading: recoveryCodesLoading } =
    useFetch("post");

  const { authenticator2faEnabled, email2faEnabled, sms2faEnabled } =
    mfaSettings || {
      authenticator2faEnabled: false,
      email2faEnabled: false,
      sms2faEnabled: false,
    };

  const {
    hasAuthenticator,
    smsOtpEnabled,
    emailOtpEnabled,
    emailUsed,
    phoneUsed,
    recoveryCodesLeft,
  } = tfa;

  const hideEmail = (email: string) => {
    return email.replace(/(.{1})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < 7; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  };

  useEffect(() => {
    getMfaSettings("Users/GetMfaSettings");
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetRecoveryCodes = () => {
    getRecoveryCodes("ManageApi/GenerateRecoveryCodes", {}, (response) => {
      navigate(TFA_RECOVERY_CODES_PATH, {
        state: {
          codes: response,
        },
      });
    });
  };

  const mfasDisabled =
    smsOtpEnabled === false &&
    emailOtpEnabled === false &&
    hasAuthenticator === false;

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa.twoFactorAuthenticationTitle")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("2fa.twoFactorAuthenticationTitle")}
        description={t("2fa.twoFactorAuthenticationInfo")}
        to="/security"
      >
        {!mfasDisabled && (
          <>
            {recoveryCodesLeft > 0 ? (
              <RecoveryCodeCard
                title={t("2fa.recoveryCodeTitle")}
                description={t("2fa.recoveryCodesActive")}
                buttonText={t("2fa.resetRecoveryCode")}
                onClick={toggle}
                noIcon
              />
            ) : (
              <RecoveryCodeCard
                title={t("2fa.noRecoveryCodesLeft")}
                description={t("2fa.mustGenerateNewRecoveryCodes")}
                buttonText={t("2fa.generateNewRecoveryCodes")}
                onClick={resetRecoveryCodes}
              />
            )}
          </>
        )}

        <div className="mt-6">
          {loading && <TfaSectionLoader itemsCount={2} />}

          {!loading && (
            <>
              {sms2faEnabled && featureEnabled["smsVerification"] === true && (
                <NavLinkWrapper
                  to={TFA_TEXT_MESSAGE_PATH}
                  lastChild={!email2faEnabled && !authenticator2faEnabled}
                >
                  <NavLinkInner
                    title={t("2fa.textMessage")}
                    message={`${
                      smsOtpEnabled
                        ? `${t("2fa.phoneNumber")} (${
                            !!phoneUsed && phoneUsed
                          }) ${t("2fa.usedForVerification")}`
                        : t("2fa.notSetupYet")
                    }
          `}
                  />
                  <Indicator isOn={smsOtpEnabled} />
                </NavLinkWrapper>
              )}

              {email2faEnabled && featureEnabled["emailVerification"] === true && (
                <NavLinkWrapper
                  to={TFA_EMAIL_PATH}
                  lastChild={!sms2faEnabled || !authenticator2faEnabled}
                >
                  <NavLinkInner
                    title={t("2fa.emailVerification")}
                    message={`${
                      emailOtpEnabled
                        ? `${t("2fa.emailAddress")}  (${
                            !!emailUsed && hideEmail(emailUsed)
                          }) ${t("2fa.usedForVerification")}`
                        : t("2fa.notSetupYet")
                    }
          `}
                  />
                  <Indicator isOn={emailOtpEnabled} />
                </NavLinkWrapper>
              )}

              {authenticator2faEnabled &&
                featureEnabled["authAppVerification"] === true && (
                  <NavLinkWrapper lastChild to={TFA_AUTH_APP_PATH}>
                    <NavLinkInner
                      title={t("2fa.authenticatorApp")}
                      message={`${
                        hasAuthenticator
                          ? t("2fa.authenticatorUsedForVerification")
                          : t("2fa.notSetupYet")
                      }
          `}
                    />
                    <Indicator isOn={hasAuthenticator} />
                  </NavLinkWrapper>
                )}
            </>
          )}
        </div>

        {visible && (
          <Modal
            hide={toggle}
            visible={visible}
            title={t("2fa.areYouSure")}
            onConfirmClick={resetRecoveryCodes}
            confirmBtnText={t("2fa.reset")}
            modalSize={ModalSize.md}
            withFooter
            disabled={recoveryCodesLoading}
            blockOutsideClick
          >
            <div className="flex mt-8 items-start">
              <Warning className="top-2 relative mr-5" />
              <p className="text-sm text-gray-700">
                {t<string>("2fa.resetRecovreyCodesInfo")}
              </p>
            </div>
          </Modal>
        )}
      </SecondaryContainer>
    </>
  );
};
