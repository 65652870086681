import { FC } from "react";
import { cx } from "../../utils";

interface IInfoTip {
  className?: string;
  title: string;
  description: string;
}

export const InfoTip: FC<IInfoTip> = ({ className, title, description }) => {
  return (
    <div className={cx(["text-neutral max-w-sm", className])}>
      <p className="font-bold text-sm">{title}</p>
      <p className="text-sm">{description}</p>
    </div>
  );
};
