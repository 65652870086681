import React from 'react'
import DatePicker from 'react-date-picker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { inputClasses } from '../../utils';

export const BirthdaySection = () => {
    const { t } = useTranslation();
    const {
      control
    } = useFormContext();
    
  return (
    <div className="relative flex flex-col sm:flex-row">
    <p className="text-black flex-shrink-0 sm:top-2 sm:w-48 relative mb-6 sm:mb-0">
      {`${t("profile.birthday")}`}
    </p>
    <div className="relative flex-1">
      <div className="w-full sm:w-1/2 ">
        <Controller
          control={control}
          name="birthdate"
          render={({ field }) => {
            return (
              <DatePicker
                calendarIcon={<Icon name="Calendar" size={18} />}
                className="w-full animate-fade-in"
                {...field}
                calendarClassName={inputClasses}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                maxDate={new Date()}
                minDate={new Date(1900, 0)}
              />
            );
          }}
        />
      </div>
    </div>
  </div>
  )
}
