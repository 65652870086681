import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Initials, InitialSize } from "../ui/Initials";
import { Animation, RoundedImg } from "../";
import { useToggle } from "../../hooks";
import { DropdownOption } from "./DropdownOption";
import { ILanguage } from "./interface";
import { cx, featureEnabled } from "../../utils";

interface ISettingsDropdownWrapper {
  user: {
    name: string;
    email: string;
    picture: string;
    isAdmin?: boolean;
  };
  changeLanguage: (code: string, lang: ILanguage) => void;
  onSignOut: () => void;
  currentLangObj: ILanguage;
  languages: ILanguage[];
}

const SettingsDropdownWrapper: FC<ISettingsDropdownWrapper> = ({
  user,
  onSignOut,
  changeLanguage,
  currentLangObj,
  languages,
}) => {
  const { toggle, visible } = useToggle();
  const { t } = useTranslation();

  const onItemClick = () => {
    document.body.click();
  };

  return (
    <div className="focus:outline-none bg-white relative z-10 w-full">
      <div className={cx(["flex p-4", !!user?.name ? "" : "items-center"])}>
        {!!user?.picture ? (
          <RoundedImg
            src={user.picture}
            alt="user"
            className="w-8 h-8 border-2 border-gray-100 flex-shrink-0"
          />
        ) : (
          <Initials
            text={!!user?.name ? user.name : user.email}
            initialSize={InitialSize.sm}
            className="flex-shrink-0"
          />
        )}

        <div className="ml-4">
          <NavLink
            to="/profile"
            onClick={onItemClick}
            className="hover text-sm mt-3 hover:text-primary break-all"
          >
            {user.name}
          </NavLink>

          <p className="text-xs text-gray-600">{user.email}</p>
        </div>
      </div>

      {featureEnabled["profileInfo"] === true && (
        <DropdownOption
          iconName="Avatar"
          isLink
          to="/profile"
          onClick={onItemClick}
        >
          {`${t("profileOverview.profile")}`}
        </DropdownOption>
      )}

      {user?.isAdmin && (
        <DropdownOption iconName="Home" onClick={onItemClick}>
          <a
            href={process.env.REACT_APP_ADMIN_PAGE}
            target="_blank"
            className="text-left"
            rel="noreferrer"
          >
            {t<string>("profileOverview.GoToPortaDashboard")}
          </a>
        </DropdownOption>
      )}

      <DropdownOption iconName="Settings" isLink to="/" onClick={onItemClick}>
        {t<string>("profileOverview.accountSettings")}
      </DropdownOption>

      {featureEnabled["language"] === true && (
        <>
          <div className="border-t border-primary-stroke my-2" />
          <DropdownOption iconName="Language" onClick={toggle}>
            <>
              {t(`languages.${currentLangObj?.name}`)}
              <div className="ml-5 group-hover:text-primaryMainText">
                <Icon name={visible ? "ArrowUp" : "ArrowDown"} size={22} />
              </div>
            </>
          </DropdownOption>

          <Animation type="height" show={visible}>
            <div>
              {languages?.map((lang: ILanguage) => (
                <DropdownOption
                  iconName="Check"
                  key={lang.id}
                  onClick={() => {
                    changeLanguage(lang.languageCode, lang);
                    onItemClick();
                  }}
                  iconColor={
                    currentLangObj?.languageCode === lang.languageCode
                      ? "text-primary-mainText"
                      : "text-white group-hover:text-gray-100"
                  }
                >
                  {t<string>(`languages.${lang.name}`)}
                </DropdownOption>
              ))}
            </div>
          </Animation>
        </>
      )}

      <div className="border-t border-primary-stroke my-2" />

      <DropdownOption iconName="Signout" onClick={onSignOut}>
        {t<string>("profileOverview.logOut")}
      </DropdownOption>
    </div>
  );
};

export default SettingsDropdownWrapper;
