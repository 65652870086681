import { FC, ReactNode } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { NavLink } from "react-router-dom";

import { Divider } from "./Divider";
import { cx } from "../../utils";

interface INavLinkWrapper {
  lastChild?: boolean;
  to: string | object;
  wrapperClassName?: string;
  dividerClassName?: string;
  children: ReactNode;
}

export const NavLinkWrapper: FC<INavLinkWrapper> = ({
  lastChild,
  to,
  children,
  wrapperClassName,
  dividerClassName,
}) => {
  return (
    <div className="sm:px-6">
      <NavLink
        to={to}
        className={cx([
          "group flex flex-1 md:pr-4 relative active py-5 sm:py-8",
          "hover:text-primary",
          wrapperClassName,
        ])}
      >
        {children}

        <div className="absolute sm:top-1/2 right-2 sm:transform -translate-y-1/2 top-14">
          <Icon name="ArrowRight" size={18} />
        </div>
      </NavLink>

      {!lastChild && <Divider className={cx(["md:my-4", dividerClassName])} />}
    </div>
  );
};
