import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { format } from "date-fns";

import { TitleSection } from "../../components/personalization";
import { SecondaryContainer } from "../../containers";
import { useFetch } from "../../hooks";
import { addPageViewEvent, cx, primaryBtnClasses } from "../../utils";
import { eventDownloadPersonalData } from "../../utils/analytics";
import {
  Spinner,
  SpinnerSize,
  BtnSize,
  BtnStyle,
  Button,
  Animation,
  Loader,
} from "../../components";
import { useOrganizationSettingsContext } from "../../context/OrganizationSettingsContext";

export const DownloadData: FC = () => {
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const { t } = useTranslation();
  const { organization } = useOrganizationSettingsContext();
  const {
    apiCall: downloadUserData,
    response: userData,
    loading,
  } = useFetch("post");
  const {
    apiCall: getDownloadStatus,
    response: downloadStatus,
    loading: downloadStatusLoading,
  } = useFetch("get");

  useEffect(() => {
    getDownloadStatus("ManageApi/DownloadUserData");
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestDownload = () => {
    downloadUserData(
      "ManageApi/DownloadUserData",
      {},
      () => {
        setDownloaded(true);
        eventDownloadPersonalData();
      },
      (error) => {
        console.log("error respoinse", error?.response?.data);
      }
    );
  };

  const dateUserDataExportAllowed =
    !!downloadStatus?.dateUserDataExportAllowed &&
    format(new Date(downloadStatus?.dateUserDataExportAllowed), "dd MMM, yyyy");

  return (
    <>
      <Helmet>
        <title>{t<string>("downloadData.downloadDataTitle")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("downloadData.downloadDataTitle")}
        description={t("downloadData.yourData")}
      >
        <div className="sn:px-16 mt-6 sm:mt-0">
          {!downloaded ? (
            <>
              <TitleSection
                title={t("downloadData.getCopyOfData")}
                message={`${t("downloadData.dataRequestInfo")}.`}
              />

              <p className="text-sm font-medium mt-6">{userData?.Email}</p>

              <Button
                type="button"
                onClick={requestDownload}
                btnStyle={BtnStyle.primary}
                btnSize={BtnSize.nomral}
                disabled={
                  loading || downloaded || !downloadStatus?.exportDataAllowed
                }
                className={cx(["mt-8 flex items-center", primaryBtnClasses])}
              >
                {loading && (
                  <Spinner
                    size={SpinnerSize.xs}
                    color="text-white"
                    className="mr-2"
                    padding="p-0"
                  />
                )}
                {t<string>("downloadData.requestDownload")}
              </Button>

              {!downloadStatus?.exportDataAllowed && (
                <>
                  {downloadStatusLoading ? (
                    <Loader />
                  ) : (
                    <p className="text-sm text-neutral mt-4">
                      {t<string>("downloadData.lastDownloadDataRequest")}{" "}
                      <strong className="font-semibold">
                        {dateUserDataExportAllowed}.
                      </strong>{" "}
                      <>
                        {t<string>("downloadData.ableToRequestTime")}{" "}
                        {!!organization?.supportEmail && (
                          <>
                            {t(
                              "downloadData.lastDownloadDataRequestAdditionalInfo"
                            )}{" "}
                            <strong>
                              <a
                                href={`mailto:${organization?.supportEmail}`}
                                className="hover:text-primary font-semibold"
                              >
                                {organization?.supportEmail}
                              </a>
                            </strong>
                            .
                          </>
                        )}
                      </>
                    </p>
                  )}
                </>
              )}
            </>
          ) : (
            <Animation type="fade-in" show={!loading}>
              <div className="flex items-center my-16">
                <div className="flex-shrink-0">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.2"
                      cx="32"
                      cy="32"
                      r="32"
                      fill="#36B37E"
                    />
                    <circle cx="32" cy="32.0016" r="22.4" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M32.0001 9.06641C19.3344 9.06641 9.06677 19.334 9.06677 31.9997C9.06677 44.6655 19.3344 54.9331 32.0001 54.9331C44.6658 54.9331 54.9334 44.6655 54.9334 31.9997C54.9334 19.334 44.6658 9.06641 32.0001 9.06641ZM42.2743 27.0359C42.8991 26.4111 42.8991 25.398 42.2743 24.7732C41.6495 24.1483 40.6364 24.1483 40.0115 24.7732L28.9524 35.8323L23.9886 30.8684C23.3637 30.2436 22.3507 30.2436 21.7258 30.8684C21.101 31.4932 21.101 32.5063 21.7258 33.1311L27.6325 39.0378C28.3615 39.7668 29.5434 39.7668 30.2724 39.0378L42.2743 27.0359Z"
                      fill="#36B37E"
                    />
                  </svg>
                </div>

                <div className="ml-6">
                  <p className="mb-2 text-black font-medium">
                    {t<string>("downloadData.downloadRequested")}
                  </p>{" "}
                  <p className="text-sm text-neutral">
                    {t<string>("downloadData.emailSentInfo1")}{" "}
                    <strong>{userData?.Email}</strong>.
                    {t<string>("downloadData.emailSentInfo2")}
                  </p>
                </div>
              </div>
            </Animation>
          )}
        </div>
      </SecondaryContainer>
    </>
  );
};
