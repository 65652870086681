import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageHeader, PageInfoItem } from "../../components";
import { MainContainer } from "../../containers";
import { addPageViewEvent, featureEnabled } from "../../utils";

export const Personalization: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("personalization.dataPersonalisation")}</title>
      </Helmet>

      <MainContainer>
        <PageHeader
          title={t("personalization.dataPersonalisation")}
          description={t("personalization.bannerTextPersonalisation")}
          iconUrl="images/personalization-icon.svg"
        />

        <div className="mt-14">
          {featureEnabled["downloadData"] === true && (
            <PageInfoItem
              title={t("personalization.downloadData")}
              description={t("personalization.downloadDataInfo")}
              iconName="download-data.png"
              to="/download-data"
            />
          )}

          {featureEnabled["deleteData"] === true && (
            <PageInfoItem
              title={t("personalization.deleteData")}
              description={`${t("personalization.deleteDataInfo")}.`}
              iconName="delete-data.png"
              to="/delete-account"
              lastChild
            />
          )}
        </div>
      </MainContainer>
    </>
  );
};
