import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Divider } from "..";
import { cx, inputClasses } from "../../utils";
import { Input, InputSize } from "../input";

export const ContactInfoSection = () => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <>
      <div className="relative flex flex-col sm:flex-row mb-8">
        <p className="text-black flex-shrink-0 sm:top-2 sm:w-48 relative mb-6 sm:mb-0">
          {`${t("contactInfo.phoneNumber")}`}
        </p>
        <div className="relative flex-1">
          <div className="w-full sm:w-1/2 ">
            <Input
              {...register("phoneNumber")}
              inputSize={InputSize.sm}
              type="text"
              className={cx(["flex-1", inputClasses])}
              maxLength={50}
            />
          </div>
        </div>
      </div>

      <Divider className="sm:ml-48 my-8" />

      <div className="relative flex flex-col sm:flex-row mb-8">
        <p className="text-black flex-shrink-0 sm:top-2 sm:w-48 relative mb-6 sm:mb-0">
          {`${t("contactInfo.emailAddress")}`}
        </p>
        <div className="relative flex-1">
          <div className="w-full sm:w-1/2 ">
            <Input
              {...register("email")}
              inputSize={InputSize.sm}
              disabled
              type="text"
              className={cx(["flex-1", inputClasses])}
            />
          </div>
        </div>
      </div>
    </>
  );
};
