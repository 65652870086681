import { FC } from "react";

// import "moment/locale/fr";
// import "moment/locale/cs";
import moment from "moment";

import { NavLink } from "react-router-dom";

import { DropdownOption, Dropdown, ThreeDotsButton } from "..";
import { IDevice } from "../../pages";
import { cx, MANAGE_DEVICES_PATH } from "../../utils";
import { Desktop, Phone } from "../icons";
import { CurrentDeviceLabel } from "./CurrentDeviceLabel";
import { useTranslation } from "react-i18next";

interface IDeviceListItem {
  lastChild?: boolean;
  device: IDevice;
  onSignOut: () => void;
}

export const DeviceListItem: FC<IDeviceListItem> = ({
  lastChild,
  device,
  onSignOut,
}) => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   moment.locale(i18n?.language);
  // }, [i18n?.language]);

  return (
    <li
      className={cx([
        "flex justify-between py-6 relative manage-devices-overlay",
        !lastChild && "border-b border-primary-stroke",
      ])}
    >
      <div className="flex items-baseline relative pl-10">
        {device.deviceType === "computer" ? (
          <Desktop className="absolute left-0 top-2" />
        ) : (
          <Phone className="absolute left-0 top-2" />
        )}

        <div>
          <div>
            <NavLink
              to={`${MANAGE_DEVICES_PATH}/${device.sessionId}`}
              className="mb-2 text-primary-mainText font-medium hover:text-primary transition capitalize"
            >
              {device?.deviceType || "Unknown device"}{" "}
              <span className="text-sm text-gray-500">
                {t("manageDevices.in")} {device?.browser}
              </span>
            </NavLink>
          </div>

          {device?.currentSession ? (
            <CurrentDeviceLabel
              text={t("manageDevices.thisDevice")}
              className="mt-2"
            />
          ) : (
            <span className="text-primary-secText">
              {device?.lastActivity && moment(device?.lastActivity).fromNow()}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center">
        <img
          src={`/images/browsers/${device?.browser?.toLowerCase()}.png`}
          alt={`${device?.browser} Logo`}
          className="w-6 mr-4"
        />
        <Dropdown
          width="w-40"
          noPadding
          dropdownContent={
            <>
              <DropdownOption
                label={t("manageDevices.viewDetails")}
                isLink
                to={`${MANAGE_DEVICES_PATH}/${device.sessionId}`}
              />

              {!device?.currentSession && (
                <DropdownOption
                  label={t("manageDevices.signOut")}
                  onClick={onSignOut}
                />
              )}
            </>
          }
        >
          <ThreeDotsButton />
        </Dropdown>
      </div>
    </li>
  );
};
