import { FC } from "react";
import { cx } from "../../utils";

interface ISectionTitle {
  className?: string;
  title: string;
  message: string;
  containerClassName?: string;
}

export const SectionTitle: FC<ISectionTitle> = ({
  className,
  title,
  message,
  containerClassName,
}) => {
  return (
    <div className={cx(["mb-4", containerClassName])}>
      <h4 className={cx(["text-black", className])}>{title}</h4>
      <p className="text-sm text-gray-700 mb-5 mt-2 sm:mb-0">{message}</p>
    </div>
  );
};
