import { FC, useEffect, useRef, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  FormGroup,
  Modal,
  ModalSize,
  ShowPassButton,
  Input,
  InputSize,
} from "../../components";
import { FormContainer, SecondaryContainer } from "../../containers";
import { inputClasses } from "../../utils/style-classes";
import { IChangePassword, IChangePawnPassword } from "../../interfaces";
import { useFetch, useHasPasswordApi, useToggle } from "../../hooks";
import { eventChangePassword } from "../../utils/analytics";
import { ChangePasswordLoader, PasswordField } from "../../components/security";
import { addPageViewEvent } from "../../utils";

export const ChangePassword: FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [formData, setFormData] = useState<IChangePawnPassword>();
  const [currentPass, setCurrentPass] = useState<boolean>(true);
  const [confirmPass, setConfirmPass] = useState<boolean>(true);

  const { apiCall: changePassword, loading } = useFetch("post");
  const { apiCall: getPasswordSettings, response: passwordSettings } =
    useFetch("get");
  const { hasPassword, loading: hasPasswordLoading } = useHasPasswordApi(true);

  const { apiCall: changePawnedPassword, loading: pawnPasswordLoading } =
    useFetch("post");

  const { toggle, visible } = useToggle();
  const { toggle: infoModalToggle, visible: infoModalVisible } = useToggle();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  const resetForm = () => {
    reset();
    setTimeout(() => {
      navigate("/security");
    }, 0);
  };

  const onChangePassword: SubmitHandler<any> = (data: IChangePassword) => {
    changePassword(
      `ManageApi/${hasPassword ? "ChangePassword" : "setPassword"}`,
      data,
      (response) => {
        if (!response?.isPasswordPwned) {
          eventChangePassword();
          toast.success(`${t("changePassword.passwordUpdatedSuccessfully")}!`);
          resetForm();
        }
      },
      (error) => {
        const { data } = error?.response;

        if (data?.statusMessage) {
          toast.error(`${t(data?.statusMessage)}!`);
        }

        const canBreachedPasswordContinue =
          data?.isPasswordPwned &&
          data?.enablePasswordPwnValidation &&
          data?.allowPwnedPassword;

        const canNotBreachedPasswordContinue =
          data?.enablePasswordPwnValidation &&
          !data?.allowPwnedPassword &&
          data?.isPasswordPwned;

        if (canBreachedPasswordContinue) {
          const passObject = {
            ...data,
            allowPwnedPassword: true,
            continueWithPwnedPassword: true,
          };
          setFormData(() => passObject);
          toggle();
        }

        if (canNotBreachedPasswordContinue) {
          infoModalToggle();
        }
      }
    );
  };

  const onChangePawnedPassword = () => {
    changePawnedPassword(
      `ManageApi/${hasPassword ? "ChangePassword" : "setPassword"}`,
      formData,
      (response) => {
        if (!response?.isPasswordPwned) {
          eventChangePassword();
          toast.success(
            t<string>("changePassword.passwordUpdatedSuccessfully")
          );
        }

        toggle();
        resetForm();
      },
      (error) => {
        const response = error?.response;
        toggle();
        toast.error(t<string>(response?.data?.statusMessage));
      }
    );
  };

  useEffect(() => {
    getPasswordSettings(`Users/AuthenticationSettings`);
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t<string>(
            hasPassword
              ? "changePassword.changePasswordTitle"
              : "security.setPassowrdTitle"
          )}
        </title>
      </Helmet>

      <SecondaryContainer
        title={t(
          hasPassword
            ? "changePassword.changePasswordTitle"
            : "security.setPassowrdTitle"
        )}
        description={t(
          hasPassword
            ? "changePassword.changePasswordInfo"
            : "security.setPasswordDescription"
        )}
      >
        {hasPasswordLoading && <ChangePasswordLoader itemsCount={1} />}

        {!hasPasswordLoading && (
          <FormContainer
            onSubmit={handleSubmit(onChangePassword)}
            disabled={loading}
          >
            <FormGroup
              label={t("changePassword.password")}
              className="flex-col sm:max-w-sm"
              lastChild
              firstChild
            >
              <div className="mb-4 sm:mb-10 relative">
                {hasPassword && (
                  <div className="relative">
                    <Input
                      {...register("oldPassword", {
                        required: `${t(
                          "changePassword.currentPasswordRequired"
                        )}`,
                      })}
                      labelProps={{
                        children: t<string>("changePassword.currentPassword"),
                        className: "text-neutral block pb-0 mb-1.5",
                      }}
                      error={!!errors.oldPassword && errors.oldPassword.message}
                      inputSize={InputSize.sm}
                      type={currentPass ? "password" : "text"}
                      className={inputClasses}
                      autoFocus
                      maxLength={150}
                    />

                    <ShowPassButton
                      passType={currentPass}
                      onClick={() => setCurrentPass(!currentPass)}
                    />
                  </div>
                )}
              </div>

              <div className="mb-4 sm:mb-10">
                <PasswordField
                  register={register}
                  errors={errors}
                  passwordSettings={passwordSettings}
                  watch={watch}
                />
              </div>

              <div className="mb-4 relative">
                <Input
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === newPassword.current ||
                      `${t("changePassword.confirmAndNewPasswordNotMatching")}`,
                  })}
                  labelProps={{
                    children: t<string>("changePassword.confirmPassword"),
                    className: "text-neutral block pb-0 mb-1.5",
                  }}
                  error={
                    !!errors.confirmPassword && errors.confirmPassword.message
                  }
                  inputSize={InputSize.sm}
                  type={confirmPass ? "password" : "text"}
                  className={inputClasses}
                  maxLength={150}
                />

                <ShowPassButton
                  passType={confirmPass}
                  onClick={() => setConfirmPass(!confirmPass)}
                />
              </div>
            </FormGroup>
          </FormContainer>
        )}

        {visible && (
          <Modal
            hide={toggle}
            visible={visible}
            title={t("changePassword.pwdDataBreachTitle")}
            onConfirmClick={onChangePawnedPassword}
            confirmBtnText={`${t("changePassword.continue")}`}
            modalSize={ModalSize.md}
            warningModal
            withFooter
            disabled={pawnPasswordLoading}
            blockOutsideClick
          >
            <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
              {t<string>("changePassword.pwdDataBreachFirstDescription")}
              {t<string>("changePassword.pwdDataBreachSecDescription")}
            </p>
          </Modal>
        )}

        {infoModalVisible && (
          <Modal
            hide={infoModalToggle}
            visible={infoModalVisible}
            title={t("changePassword.pwdDataBreachTitle")}
            infoModal
            modalSize={ModalSize.md}
            warningModal
            withFooter
            blockOutsideClick
          >
            <p className="text-sm text-gray-700 sm:my-8 mb-6 sm:mb-8">
              {t<string>("changePassword.pwdDataBreachFirstDescription")}
            </p>
          </Modal>
        )}
      </SecondaryContainer>
    </>
  );
};
