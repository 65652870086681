import { FC } from "react";
import { cx, primaryBtnClasses } from "../../utils";
import { BtnStyle, Button, BtnSize } from "../button";

interface IRecoveryCodeCard {
  className?: string;
  title: string;
  description: string;
  buttonText: string;
  noButton?: boolean;
  noIcon?: boolean;
  onClick?: () => void;
}

export const RecoveryCodeCard: FC<IRecoveryCodeCard> = ({
  className,
  buttonText,
  onClick,
  title,
  description,
  noButton,
  noIcon,
}) => {
  return (
    <div
      className={cx([
        "shadow-card-md rounded-xl border border-gray-200 p-4 sm:p-6 flex sm:flex-row flex-col justify-between sm:items-center mt-4 sm:mt-0",
        className,
      ])}
    >
      <div className="flex items-baseline">
        {!noIcon && (
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="top-0.5 relative mr-5"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.77774 1.30759C7.74632 -0.435865 10.2537 -0.43586 11.2223 1.30759L17.6764 12.9251C18.6178 14.6195 17.3925 16.7019 15.4542 16.7019H2.54583C0.607462 16.7019 -0.617784 14.6195 0.323571 12.9251L6.77774 1.30759ZM8.99997 4.62695C9.40497 4.62695 9.73329 4.95527 9.73329 5.36027V10.2491C9.73329 10.6541 9.40497 10.9824 8.99997 10.9824C8.59497 10.9824 8.26665 10.6541 8.26665 10.2491V5.36027C8.26665 4.95527 8.59497 4.62695 8.99997 4.62695ZM8.99998 14.1602C9.53998 14.1602 9.97774 13.7224 9.97774 13.1824C9.97774 12.6424 9.53998 12.2047 8.99998 12.2047C8.45997 12.2047 8.02222 12.6424 8.02222 13.1824C8.02222 13.7224 8.45997 14.1602 8.99998 14.1602Z"
              fill="#FFAB00"
            />
          </svg>
        )}

        <div>
          <h6 className="font-medium text-black mb-1">{title}</h6>
          <p className="text-sm text-gray-700">{description}</p>
        </div>
      </div>

      {!noButton && (
        <Button
          type="button"
          btnStyle={BtnStyle.primary}
          btnSize={BtnSize.nomral}
          className={cx([primaryBtnClasses, "whitespace-nowrap mt-4 sm:mt-0"])}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
