import React, { ReactNode, ReactElement } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { SidebarMenu, SidebarMenuProps } from "./SidebarMenu";
import { cx } from "../../utils/classnames";
import { Animation } from "../animation";

export interface ItemProps extends SidebarItemProps {
  nestedList?: SidebarItemProps[];
}

export interface SidebarItemProps {
  to?: string;
  icon?: ReactElement;
  title: string;
  depth?: number;
  arrowIcon?: ReactNode;
  openList?: string | null;
  setOpenList?: (val: string | null) => void;
  nestedList?: ItemProps[];
  renderItem?: SidebarMenuProps["renderItem"];
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  nestedList,
  depth,
  openList,
  setOpenList,
  to,
  arrowIcon = <Icon name="ArrowDown" />,
  renderItem,
}) => {
  const _onClick = () => {
    if (setOpenList)
      if (openList === title) setOpenList(null);
      else setOpenList(title);
  };
  if (nestedList)
    return (
      <li className={cx(["select-none text-sm mt-0.5", depth === 0 && "ml-4"])}>
        <div
          className="hover:bg-blue-200 hover:bg-opacity-20 hover:text-primary transition duration-150 rounded-l-md"
          style={{ willChange: "transform", backfaceVisibility: "hidden" }}
        >
          <div
            className="flex items-center py-3 transform-gpu transition translate-x-0 hover:translate-x-1 duration-300 "
            style={{ willChange: "transform", backfaceVisibility: "hidden" }}
            onClick={_onClick}
            role="button"
          >
            {icon ? (
              <span
                className="w-5 h-5 ml-3 hidden sm:inline"
                onClick={_onClick}
                role="button"
              >
                {icon}
              </span>
            ) : null}
            <span
              className={cx([
                "ml-4 flex-1 font-medium whitespace-nowrap sm:whitespace-normal",
                !icon && "pl-8 ",
              ])}
            >
              {title}
            </span>
            <span
              className={`mr-3 transition transform-gpu ${
                openList !== title
                  ? "rotate-0"
                  : `${depth && depth >= 1 ? "-rotate-90" : "rotate-180"}`
              }`}
            >
              {arrowIcon}
            </span>
          </div>
        </div>
        <Animation type="height" show={openList === title} duration={300}>
          <div>
            <SidebarMenu
              list={nestedList}
              depth={(depth || 0) + 1}
              renderItem={renderItem}
              arrowIcon={<Icon name="ArrowRight" />}
            />
          </div>
        </Animation>
      </li>
    );
  if (to) {
    return (
      <li className="mt-0.5">
        {renderItem && renderItem({ to, icon, nestedList, title, depth })}
      </li>
    );
  } else return null;
};
