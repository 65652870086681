import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageHeader, PageInfoItem } from "../../components";
import { SectionLoader } from "../../components/account";
import { MainContainer } from "../../containers";
import { useFetch, useHasPasswordApi } from "../../hooks";
import {
  addPageViewEvent,
  featureEnabled,
  MANAGE_DEVICES_PATH,
  MANAGE_PERMISSIONS_PATH,
  RECOVERY_EMAIL_PATH,
  tfaDisabled,
  TFA_PATH,
} from "../../utils";

export const Security: FC = () => {
  const { t } = useTranslation();
  const { hasPassword, loading } = useHasPasswordApi(true);
  const {
    apiCall: getMfaSettings,
    response: mfaSettings,
    loading: mfaSettingsLoading,
  } = useFetch("get");

  const { authenticator2faEnabled, email2faEnabled, sms2faEnabled } =
    mfaSettings || {
      authenticator2faEnabled: false,
      email2faEnabled: false,
      sms2faEnabled: false,
    };

  useEffect(() => {
    getMfaSettings("Users/GetMfaSettings");
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mfaSettingsDisabled =
    authenticator2faEnabled === false &&
    email2faEnabled === false &&
    sms2faEnabled === false;

  return (
    <>
      <Helmet>
        <title>{t<string>("security.securityTitle")}</title>
      </Helmet>

      <MainContainer>
        <PageHeader
          title={t("security.securityTitle")}
          description={t("security.securityInfo")}
          iconUrl="images/security-icon.svg"
        />

        <div className="mt-14">
          {(loading || mfaSettingsLoading) && <SectionLoader itemsCount={2} />}

          {!loading && !mfaSettingsLoading && (
            <>
              {featureEnabled["changePassword"] === true && (
                <PageInfoItem
                  title={t(
                    hasPassword
                      ? "security.changePassword"
                      : "security.setPassowrdTitle"
                  )}
                  description={t(
                    hasPassword
                      ? "security.changePasswordInfo"
                      : "security.setPasswordDescription"
                  )}
                  iconName="change-password.png"
                  to="/change-password"
                  lastChild={mfaSettingsDisabled}
                />
              )}

              {!mfaSettingsDisabled &&
                !tfaDisabled &&
                featureEnabled["twoFactorAuth"] === true && (
                  <PageInfoItem
                    title={t("security.twoFactorAuthentication")}
                    description={t("security.twoFactorAuthenticationInfo")}
                    iconName="/two-factor.png"
                    to={TFA_PATH}
                    lastChild={
                      !featureEnabled["recoveryEmail"] &&
                      !featureEnabled["manageDevices"]
                    }
                  />
                )}

              {featureEnabled["recoveryEmail"] === true && (
                <PageInfoItem
                  title={t("security.recoverEmailTitle")}
                  description={t("security.recoverEmailDescription")}
                  iconName="recovery-email.png"
                  to={RECOVERY_EMAIL_PATH}
                  lastChild={featureEnabled["manageDevices"] === false}
                />
              )}

              {featureEnabled["manageDevices"] === true && (
                <PageInfoItem
                  title={t("security.manageDevices")}
                  description={t("security.manageDevicesDesc")}
                  iconName="/manage-devices.png"
                  to={MANAGE_DEVICES_PATH}
                />
              )}

              {featureEnabled["permissions"] === true && (
                <PageInfoItem
                  title={t("security.permissions")}
                  description={t("security.permissionsDesc")}
                  iconName="/permissions.png"
                  to={MANAGE_PERMISSIONS_PATH}
                  lastChild
                />
              )}
            </>
          )}
        </div>
      </MainContainer>
    </>
  );
};
