import { FC } from "react";

interface ITitleSection {
  title: string;
  message?: string;
}

export const TitleSection: FC<ITitleSection> = ({ title, message }) => {
  return (
    <div className="flex justify-between">
      <div className="">
        <p className="mb-2 text-black font-medium">{title}</p>
        <p className="text-sm text-neutral">{message}</p>
      </div>
    </div>
  );
};
