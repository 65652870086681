import { FC, ImgHTMLAttributes } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils/classnames";

export const RoundedImg: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  className,
  src,
  onClick = undefined,
  ...props
}) => (
  <div
    onClick={onClick}
    className={cx([
      "flex items-center justify-center select-none bg-gray-200 h-8 w-8 rounded-full overflow-hidden",
      className,
    ])}
  >
    {src ? (
      <img
        {...props}
        src={src}
        className="object-cover w-full h-full"
        alt="Avatar"
      />
    ) : (
      <Icon name="Avatar" />
    )}
  </div>
);
