import { FC } from "react";

import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { ButtonProps } from "../button";

interface IAuthAppItem extends ButtonProps {
  className?: string;
  qrObject: any;
  loading?: boolean;
}

export const AuthAppItem: FC<IAuthAppItem> = ({ qrObject, loading }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-8 flex flex-col justify-betwween items-start sm:flex-row">
      <div className="sm:pr-4 md:pr-48">
        <h4 className="mb-6">
          {t<string>("2fa/authenticatorApp.configureAuthenticatorApp")}
        </h4>
        <p className="mb-4 text-sm text-gray-700">
          {t<string>("2fa/authenticatorApp.authenticatorAppStepsInfo")}
        </p>

        <ul className="relative text-black text-sm list-inside pl-5">
          <li className="pl-2 mb-6">
            <span className="absolute left-4">-</span>
            {t<string>("2fa/authenticatorApp.authenticatorAppInfoStep1")}
          </li>

          <li className="pl-2 mb-6">
            <span className="absolute left-4">-</span>
            {t<string>("2fa/authenticatorApp.authenticatorAppInfoStep2")}

            {loading ? (
              <ContentLoader
                speed={2}
                width={300}
                height={37}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                className="my-3"
              >
                <rect x="0" y="0" rx="4" width="300" height="37" />
              </ContentLoader>
            ) : (
              !!qrObject && (
                <div className="p-2 my-3 rounded bg-gray-200 font-bold text-sm animate-fade-in">
                  {qrObject.sharedKey}
                </div>
              )
            )}
            {t<string>(
              "2fa/authenticatorApp.authenticatorAppInfoStep2Additional"
            )}
          </li>

          <li className="pl-2 mb-6">
            <span className="absolute left-4">-</span>
            {t<string>("2fa/authenticatorApp.authenticatorAppInfoStep3")}
          </li>
        </ul>
      </div>

      <div className="flex-shrink-0 w-full sm:w-32 sm:h-32 md:w-48 md:h-48">
        {loading ? (
          <ContentLoader
            speed={2}
            width={180}
            height={180}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="4" width="180" height="180" />
          </ContentLoader>
        ) : (
          !!qrObject && (
            <div className="animate-fade-in">
              <QRCode value={qrObject.authenticatorUri} size={180} />
            </div>
          )
        )}
      </div>
    </div>
  );
};
