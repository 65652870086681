import { FC, MouseEvent } from "react";
import { cx } from "../../../utils";

import TermsSidebarItem from "./TermsSidebarItem";

interface ITermsSidebarOption {
  label: string;
  sectionId: string;
}

interface ITermsSidebar {
  options: ITermsSidebarOption[];
  className?: string;
}

export const TermsSidebar: FC<ITermsSidebar> = ({ options, className }) => {
  const scrollTo = (id: string, e: MouseEvent<HTMLButtonElement>) => {
    const buttons = document.querySelectorAll(".list-item"),
      section = document.getElementById(id),
      target = e.target as HTMLButtonElement;

    buttons?.forEach((node) => {
      node.classList.remove("text-primary", "bg-blue-bg");
    });

    target.classList.add("text-primary", "bg-blue-bg");

    section?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <aside
      className={cx(["bg-white w-72 hidden md:inline", className && className])}
    >
      <div className="pl-3 sticky top-5">
        <ul>
          {options.map((option, index) => {
            return (
              <TermsSidebarItem
                key={index}
                onClick={(e) => scrollTo(option.sectionId, e)}
                label={option.label}
              />
            );
          })}
        </ul>
      </div>
    </aside>
  );
};

export default TermsSidebar;
