import { cx } from "../../utils";

export enum InitialSize {
  sm = "w-8 h-8 text-sm",
  md = "w-16 h-16 text-4xl",
  lg = "w-20 h-20 sm:w-36 sm:h-36 text-xl sm:text-6xl",
}

interface IInitials {
  className?: string;
  text: string;
  initialSize?: InitialSize;
}

export const Initials: React.FC<IInitials> = ({
  className,
  text,
  initialSize = InitialSize.lg,
}) => {
  return (
    <div
      className={cx([
        "bg-primary-stroke rounded-full flex items-center justify-center text-primary-secText",
        className,
        initialSize,
      ])}
    >
      {text?.charAt(0).toUpperCase()}
    </div>
  );
};
