import { FC, useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { FormGroup, InfoTip, InputSize, Input } from "../../components";
import { FormContainer, SecondaryContainer } from "../../containers";
import { useFetch, useToggle } from "../../hooks";
import {
  MessageAlert,
  SectionTitle,
  StatusMessage,
  TextMessageModal,
} from "../../components/security";
import { useTfaContext } from "../../context/TfaContext";
import { addPageViewEvent, inputClasses, TFA_PATH } from "../../utils";
import { IOtpSms } from "../../interfaces";
import { eventDisableTfa, TfaTypes } from "../../utils/analytics";

export const TextMessage: FC = () => {
  const { t } = useTranslation();
  const { tfa, setTfaData } = useTfaContext();
  const { visible, toggle } = useToggle();
  const [optObj, setOptObj] = useState<IOtpSms>();
  const { smsOtpEnabled, smsOtpEnabledDate, phoneUsed } = tfa;

  const { apiCall: sendOtpViaSMS, loading } = useFetch("post");
  const { apiCall: resetSmsOtp, loading: resetSmsOtpLoading } =
    useFetch("post");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSendOtpViaSMS: SubmitHandler<any> = (data: IOtpSms) => {
    const phoneNumber = data?.phoneNumber.replace(/\s+/g, "");
    const otpObject = {
      phoneNumber,
      otpCode: "string",
      otpToken: "string",
    };

    sendOtpViaSMS(
      "ManageApi/SendOtpViaSMS",
      otpObject,
      (response) => {
        toast.success(t<string>("2fa/textMessage.sixDigitCodeSent"));
        toggle();
        setOptObj(response);
      },
      (error) => {
        toast.error(
          !!error.response.data?.errors?.PhoneNumber &&
            error.response.data?.errors?.PhoneNumber[0]
        );
      }
    );
  };

  const onResetSmsOtp = () => {
    resetSmsOtp("ManageApi/ResetSmsOtp", {}, () => {
      toast.success(t<string>("2fa/textMessage.textMessageAuthenticatorReset"));

      setTfaData({
        ...tfa,
        smsOtpEnabledDate: false,
        smsOtpEnabled: false,
      });

      eventDisableTfa(TfaTypes.sms);
    });
  };

  useEffect(() => {
    addPageViewEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa/textMessage.2faTextMessageTitle")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("2fa/textMessage.2faTextMessageTitle")}
        noDescription={smsOtpEnabled}
        description={t("2fa/textMessage.textMessageInfo")}
        to={TFA_PATH}
        headlineContent={
          smsOtpEnabled && (
            <MessageAlert
              message={`${t(
                "2fa/textMessage.textMessageIsOnSince"
              )} ${smsOtpEnabledDate}`}
              onClick={onResetSmsOtp}
              disabled={resetSmsOtpLoading}
            />
          )
        }
      >
        {smsOtpEnabled && (
          <StatusMessage
            icon="CommentAlt"
            title={t("2fa/textMessage.2faTextMessageTitle")}
            subTitle={!!phoneUsed && phoneUsed}
            message={t("2fa/textMessage.verificationCodesSent")}
            children={<></>}
          />
        )}

        {!smsOtpEnabled && (
          <FormContainer
            saveBtnLabel={t("2fa/textMessage.confirm")}
            saveBtnType="button"
            onSubmit={handleSubmit(onSendOtpViaSMS)}
            disabled={loading}
          >
            <SectionTitle
              title={t("2fa/textMessage.setupYourPhone")}
              message={t("2fa/textMessage.phoneNumberRequirement")}
            />

            <FormGroup
              label={t("2fa/textMessage.phoneNumber")}
              lastChild
              className="flex-col"
            >
              <div>
                <div className="flex items-start flex-1">
                  {/* <Dropdown
                    triggerProps={{ children: prefix }}
                    wrapperClassName="text-sm leading-4.5 rounded border border-gray-300 py-2 px-3"
                  >
                    <DropdownWrapper
                      onClick={(event: MouseEvent<HTMLButtonElement>) =>
                        setPrefix(event?.currentTarget.id)
                      }
                      list={[
                        { id: "+1", children: "1" },
                        { id: "+383", children: "383" },
                        { id: "+386", children: "386" },
                      ]}
                    />
                  </Dropdown> */}

                  <div className="flex-1 md:w-1/2 md:flex-none">
                    <Input
                      {...register("phoneNumber", {
                        required: `${t("2fa/textMessage.phoneNumberRequired")}`,
                      })}
                      labelProps={{
                        children: t<string>("2fa/textMessage.phoneNumber"),
                        className: "text-neutral block pb-0 mb-1.5",
                      }}
                      error={!!errors.phoneNumber && errors.phoneNumber.message}
                      inputSize={InputSize.sm}
                      className={inputClasses}
                      autoFocus
                    />
                  </div>
                </div>

                <InfoTip
                  className="mt-8"
                  title={`${t("2fa/textMessage.info")}:`}
                  description={t("2fa/textMessage.phoneNumberUsage")}
                />
              </div>
            </FormGroup>
          </FormContainer>
        )}
      </SecondaryContainer>

      {visible && (
        <TextMessageModal visible={visible} hide={toggle} optObj={optObj} />
      )}
    </>
  );
};
