import { FC, useEffect, useState } from "react";

import moment from "moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { Input, InputSize, Loader } from "../../components";
import {
  MessageAlert,
  RecoveryEmailModal,
  SectionTitle,
  StatusMessage,
} from "../../components/security";
import { FormContainer, SecondaryContainer } from "../../containers";
import { useFetch, useToggle } from "../../hooks";
import { addPageViewEvent, cx, getUserInfo, inputClasses } from "../../utils";
import { validateEmail } from "../../utils/validate-email";

export interface IRecoveryEmailResponse {
  email: string;
  otpCode?: string;
  otpToken?: string;
}

export interface IRecoveryEmail {
  email: string;
  updatedRecoveryDate: string;
  requestedDeleteRecoveryActive: boolean;
}

export const RecoveryEmail: FC = () => {
  const { t } = useTranslation();
  const { visible, toggle } = useToggle();
  const {
    profile: { email },
  } = getUserInfo();
  const [recoveryEmail, setRecoveryEmail] = useState<IRecoveryEmail>({
    email: "",
    updatedRecoveryDate: "",
    requestedDeleteRecoveryActive: false,
  });

  const [recoveryEmailData, setRecoveryEmailData] =
    useState<IRecoveryEmailResponse>({
      email: "",
      otpCode: "",
      otpToken: "",
    });

  const { apiCall: recoveryEmailSetup, loading } = useFetch("post");
  const { apiCall: recoveryEmailReset, loading: recoveryEmailResetLoading } =
    useFetch("post");
  const {
    apiCall: getRecoveryEmail,
    response,
    loading: gerRecoveryEmailloading,
  } = useFetch("get");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getRecoveryEmail(`ManageApi/GetRecoveryEmail`);
    addPageViewEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRecoveryEmail(response);
  }, [response]);

  const onRecoveryEmailSetup: SubmitHandler<any> = (data: {
    email: string;
  }) => {
    recoveryEmailSetup(
      `ManageApi/AddRecoveryEmail`,
      data,
      (data) => {
        toggle();
        setRecoveryEmailData(data);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const onResetRecoveryEmail = () => {
    recoveryEmailReset(
      `ManageApi/RemoveRecoveryEmail`,
      {},
      (res) => {
        toast.success(res);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("recoverEmail.title")}</title>
      </Helmet>

      {gerRecoveryEmailloading && <Loader />}

      {!gerRecoveryEmailloading && (
        <SecondaryContainer
          title={t("recoverEmail.title")}
          description={
            (!recoveryEmail?.email &&
              t("recoverEmail.recoverEmailDescription")) ||
            ""
          }
          to="/security"
          headlineContent={
            !!recoveryEmail?.email && (
              <MessageAlert
                message={`${t("recoverEmail.recoveryEmailEnabledSince")} ${
                  !!recoveryEmail?.updatedRecoveryDate
                    ? moment(recoveryEmail?.updatedRecoveryDate).format(
                        "DD/MM/YYYY"
                      )
                    : ""
                }`}
                onClick={onResetRecoveryEmail}
                buttonText={t("recoverEmail.reset")}
                disabled={recoveryEmailResetLoading}
              />
            )
          }
        >
          {!!recoveryEmail?.email && (
            <StatusMessage
              icon="MessageAlt"
              title={recoveryEmail?.email}
              subTitle={t("recoverEmail.yourRecoveryEmail")}
              children={<></>}
              // message={`${t("recoverEmail.added")}: 1 Apr 2021`}
            />
          )}

          {!recoveryEmail?.email && (
            <>
              <SectionTitle
                title={t("recoverEmail.infoTitle")}
                message={t("recoverEmail.infoDescription")}
                containerClassName="mb-12"
              />

              <FormContainer
                onSubmit={handleSubmit(onRecoveryEmailSetup)}
                disabled={loading}
              >
                <div className="relative flex flex-col sm:flex-row mb-8">
                  <p className="text-black flex-shrink-0 sm:top-2 sm:w-48 relative mb-6 sm:mb-0">
                    {t<string>("recoverEmail.emailAddress")}
                  </p>
                  <div className="relative flex-1">
                    <div className="w-full sm:w-1/2 ">
                      <Input
                        {...register("email", {
                          required: `${t("recoverEmail.emailFieldRequired")}`,
                          validate: {
                            emailNotValid: (value) =>
                              validateEmail(value) ||
                              `${t("recoverEmail.emailFieldNotValid")}`,
                            sameEmail: (value) =>
                              value !== email ||
                              `${t("recoverEmail.emailNotLikeLoggedinEmail")}`,
                          },
                        })}
                        error={!!errors.email && errors.email.message}
                        inputSize={InputSize.sm}
                        type="text"
                        className={cx(["flex-1", inputClasses])}
                      />
                    </div>
                  </div>
                </div>
              </FormContainer>
            </>
          )}
        </SecondaryContainer>
      )}

      {visible && (
        <RecoveryEmailModal
          data={recoveryEmailData}
          setRecoveryEmailData={setRecoveryEmailData}
          setRecoveryEmail={setRecoveryEmail}
          visible={visible}
          hide={toggle}
        />
      )}
    </>
  );
};
