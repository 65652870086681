import { FC } from "react";

interface INavLinkInner {
  title: string;
  message: string;
}

export const NavLinkInner: FC<INavLinkInner> = ({ message, title }) => {
  return (
    <div className="pr-14">
      <h6 className="font-medium group-hover:text-primary text-black">
        {title}
      </h6>
      <p className="text-sm mt-4 sm:mt-0 text-gray-700">{message}</p>
    </div>
  );
};
