import { FC } from "react";
import { SidebarMenu, SidebarMenuProps } from "./SidebarMenu";
import { cx } from "../../utils/classnames";

export interface SidebarProps extends SidebarMenuProps {
  wrapperClassName?: string;
}

export const Sidebar: FC<SidebarProps> = ({
  wrapperClassName,
  ulProps,
  list,
  ...props
}) => {
  return (
    <aside
      className={cx([
        "sm:py-4 bg-white sm:w-72 flex-none sm:border-none border-b border-primary-stroke",
        wrapperClassName,
      ])}
    >
      <SidebarMenu ulProps={ulProps} list={list} {...props} depth={0} />
    </aside>
  );
};
