import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../hooks";
import { colors, cx } from "../../utils";
import { Dropdown } from "../dropdown";
import { Dots } from "../icons";

interface IApplication {
  name: string;
  picture: string;
  url: string;
  colorId: number;
}

export const ApplicationsDropdown = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const { apiCall: GetApplications, response: applications } = useFetch("get");

  useEffect(() => {
    GetApplications("ManageApi/GetApplicationsToDisplay");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dropdownVisiblity = (visiblity: boolean) => {
    setVisible(visiblity);
  };

  if (applications?.length < 1) {
    return <></>;
  }

  return (
    <Dropdown
      width="w-80"
      noPadding
      dropdownVisibility={dropdownVisiblity}
      innerElementClassName="h-8 w-8 flex items-center"
      dropdownClassName="max-h-96 overflow-auto px-2 py-4"
      style={{ minHeight: 200 }}
      dropdownContent={
        <>
          <h4 className="text-sm text-primary-secText pb-2 w-full border-b border-primary-stroke mb-4">
            {t<string>("profileOverview.services")}
          </h4>
          <div className="flex flex-1 w-full flex-wrap gap-1">
            {applications?.map((app: IApplication) => (
              <a
                href={app.url}
                target="_blank"
                className="w-24 h-24 relative"
                rel="noreferrer"
              >
                <div className="flex group items-center text-center flex-col justify-cenr absolute left-0 top-0 w-full h-full p-2 rounded transition hover:bg-gray-100 focus:bg-gray-200 hover:z-10">
                  {app?.picture ? (
                    <div className="w-10 h-10 border border-primary-stroke rounded flex-shrink-0">
                      <img
                        src={app?.picture}
                        alt={`${app.name} Logo`}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  ) : (
                    <div
                      className="w-10 h-10 border flex items-center justify-center font-medium flex-shrink-0 rounded"
                      style={{
                        backgroundColor:
                          colors[(app?.colorId || 0) % colors.length].bgColor,
                        color:
                          colors[(app?.colorId || 0) % colors.length].textColor,
                      }}
                    >
                      {app?.name?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  <span
                    style={{ maxWidth: 80 }}
                    className="text-xs break-words font-medium mt-2 overflow-hidden overflow-ellipsis whitespace-nowrap max-w-full group-hover:whitespace-normal"
                  >
                    {app?.name}
                  </span>
                </div>
              </a>
            ))}
          </div>
        </>
      }
      className="mr-4"
    >
      <button
        type="button"
        className={cx([
          "hover:bg-gray-100 focus:bg-gray-200 h-8 w-8 p-2 flex items-center justify-center rounded-full hover:shadow-outlineGray transition cursor-pointer focus:shadow-outlineGray",
          visible && "shadow-outlineGray bg-gray-200",
        ])}
      >
        <span>
          <Dots />
        </span>
      </button>
    </Dropdown>
  );
};
