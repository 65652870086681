import { FC, useEffect } from "react";

import { format, isValid } from "date-fns";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Divider, Spinner } from "../../components";
import {
  BirthdaySection,
  ChangePhotoProfile,
  ContactInfoSection,
  FullNameSection,
  GenderSection,
  PasswordSection,
  PhotoProfileModal,
  TitleSection,
} from "../../components/account";
import { FormContainer, SecondaryContainer } from "../../containers";
import { useUserContext } from "../../context/UserContext";
import { useFetch, useHasPasswordApi, useToggle } from "../../hooks";
import { addPageViewEvent } from "../../utils";

export const Profile: FC = () => {
  const {
    user,
    setUserData,
    picture,
    loading: getUserLoading,
  } = useUserContext();

  const { visible, toggle } = useToggle();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { gender, passwordLastChanged } = !!user && user;
  const { apiCall: updateUserProfile, loading } = useFetch("post");
  const { hasPassword } = useHasPasswordApi(true);

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  let d: string = !!user?.birthdate && user?.birthdate?.split("/");
  let userBirthdate = new Date(+d[2], +d[1] - 1, +d[0]);

  useEffect(() => {
    const userObj = {
      ...user,
      birthdate: isValid(userBirthdate) ? userBirthdate : null,
    };

    if (user) reset(userObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, user]);

  useEffect(() => {
    addPageViewEvent();
  }, []);

  const onUpdateUserProfile = (data: any) => {
    const birthdate = !!data?.birthdate
      ? format(data?.birthdate, "dd/MM/yyyy")
      : null;
    const profileFields = {
      ...data,
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      email: user?.email,
      phoneNumber: data.phoneNumber?.trim() || null,
      birthdate,
    };

    updateUserProfile(
      "ManageApi/UserProfile",
      profileFields,
      (response) => {
        toast.success(`${t("profile.profileUpdatedSuccessfully")}!`);
        setUserData({
          ...response,
          birthdate,
        });
        // try {
        //   (window as any).MeiroEvents.track("customEvent", {
        //     event_name: "edit_account_cnccenter",
        //     first_name: response?.firstName,
        //     last_name: response?.lastName,
        //     birthdate,
        //     gender: response?.gender,
        //     email: response?.email,
        //     phone_number: response?.phoneNumber,
        //     form_id: "edit_account",
        //     page_url: document.location.origin + document.location.pathname,
        //   });
        // } catch (error) {
        //   console.log("Meiro event profile error", error);
        // }

        navigate("/");
      },
      (error) => {
        const response = error?.response?.data;

        if (!!response?.errors?.FirstName) {
          toast.error(`${t(response?.errors?.FirstName[0])}`);
        }

        if (!!response?.errors?.LastName) {
          toast.error(`${t(response?.errors?.LastName[0])}`);
        }

        if (!!response?.errors?.PhoneNumber) {
          toast.error(`${t(response?.errors?.PhoneNumber[0])}`);
        }
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("profile.profileInfo")}</title>
      </Helmet>

      <SecondaryContainer
        title={t("profile.profileInfo")}
        description={t("profile.editProfileInfo")}
        to="/"
      >
        {getUserLoading ? (
          <Spinner />
        ) : (
          <FormContainer
            onSubmit={handleSubmit(onUpdateUserProfile)}
            disabled={loading}
          >
            <TitleSection text={`${t("profile.details")}`} />

            <ChangePhotoProfile
              userName={user?.firstName || user?.email}
              picture={picture}
              toggle={toggle}
            />

            <FormProvider {...methods}>
              <FullNameSection />
              <Divider className="sm:ml-48 my-8" />
              <BirthdaySection />
              <Divider className="sm:ml-48 my-8" />
              <GenderSection gender={gender} />
              <Divider className="sm:ml-48 mt-8 sm:mb-0 mb-8" />
              <PasswordSection
                hasPassword={hasPassword}
                passwordLastChanged={passwordLastChanged}
              />
              <Divider className="mb-8" />
              <TitleSection text={`${t("contactInfo.contactInfoTitle")}`} />
              <ContactInfoSection />
            </FormProvider>
          </FormContainer>
        )}

        {visible && <PhotoProfileModal toggle={toggle} visible={visible} />}
      </SecondaryContainer>
    </>
  );
};
