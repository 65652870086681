import { FC, ReactNode } from "react";
import { cx } from "../../utils";

interface IHalfColumn {
  className?: string;
  children: ReactNode;
  left?: boolean;
  right?: boolean;
}

export const HalfColumn: FC<IHalfColumn> = ({
  className,
  children,
  left,
  right,
}) => {
  return (
    <div
      className={cx([
        "w-full sm:w-1/2 mb-4 sm:mb-0",
        left && "sm:pr-3",
        right && "sm:pl-3",
        className,
      ])}
    >
      {children}
    </div>
  );
};
